import { SideNavLinkProps } from "./SideNavLink/SideNavLink";

const auctionLinks: SideNavLinkProps[] = [
    {
        faIconString: "fas fa-home",
        text: "Dashboard",
        to: "/auction",
    },
    {
        faIconString: "fas fa-calendar",
        text: "Sales",
        to: "/auction/sales",
    },
    {
        faIconString: "fas fa-calendar",
        text: "Lot Vehicles",
        to: "/auction/sale/lot",
    },
];

export default auctionLinks;
