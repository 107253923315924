import { G3DataTable, IconButton } from "@g3r-developers/g3-common";
import { PagingModel } from "@g3r-developers/vamp-types/dist/paging-model";
import { VendorVehicleJobDataTableReturnModel } from "@g3r-developers/vamp-types/dist/VehicleJob/VendorVehicleJobDataTableReturnModel";
import VulnerableCell from "Components/Common/VulnerableCell";
import { ConsignmentStatus } from "Enums/ConsignmentStatus";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { getIntArrayFromParams, tryGetParam } from "Services/Utils";
import vendorVehicleJobService from "Services/Vendor/VendorVehicleJobService";
import { VendorClosureActionCell } from "./VendorClosureActionCell";

export interface VehicleJobSearchModel extends PagingModel {
    vehicle_job_status_ids: number[] | null;
    instruction_type_ids: number[] | null;
    consignment_status_ids: number[] | null;
    e_recovery_service_ids: number[] | null;
    keyword: string | null;
    date_from: string | null;
    date_to: string | null;
    overdue: boolean | null;
    out_of_time: boolean | null;
}

export default function VendorClosureReviewPage() {
    const { search } = useLocation();
    const params = useMemo(() => new URLSearchParams(search), [search]);

    const [filters, setFilters] = useState<VehicleJobSearchModel>({
        date_from: tryGetParam(params, "date_from"),
        date_to: tryGetParam(params, "date_to"),
        keyword: tryGetParam(params, "keyword"),
        overdue: tryGetParam(params, "overdue") === "true",
        out_of_time: tryGetParam(params, "out_of_time") === "true",
        vehicle_job_status_ids: getIntArrayFromParams(params, "vehicle_job_status_ids"),
        instruction_type_ids: getIntArrayFromParams(params, "instruction_type_ids"),
        consignment_status_ids: [ConsignmentStatus.Aborted, ConsignmentStatus.Completed],
    } as VehicleJobSearchModel);

    const handleReload = useCallback(() => setFilters(p => ({ ...p })), []);

    const columns = useMemo(
        () => [
            {
                name: "Agreement No.",
                selector: (row: VendorVehicleJobDataTableReturnModel) => row.agreement_num,
            },
            {
                name: "Registration",
                selector: "vrm",
            },
            {
                name: "Customer Name",
                cell: (row: VendorVehicleJobDataTableReturnModel) =>
                    row.vehicle_instruction_service?.vehicle_instruction?.customer_contact?.full_name ?? "N/A",
            },
            {
                name: "Closure Date",
                selector: (row: VendorVehicleJobDataTableReturnModel) => row.closure_date_string,
            },
            {
                name: "Service Required",
                selector: (row: VendorVehicleJobDataTableReturnModel) => row.vehicle_job_type,
            },
            {
                name: "Service Provider",
                selector: (row: VendorVehicleJobDataTableReturnModel) => row.supplier_name,
            },
            {
                name: "Vulnerable",
                cell: (row: VendorVehicleJobDataTableReturnModel) => (
                    <VulnerableCell vulnerable={row.vehicle_instruction_service?.vehicle_instruction?.vulnerable} />
                ),
                center: true,
            },
            {
                name: "Closure Reason",
                selector: (row: VendorVehicleJobDataTableReturnModel) => row.closure_reason,
            },
            {
                name: "Instruction Phase",
                cell: (row: VendorVehicleJobDataTableReturnModel) =>
                    row.vehicle_instruction_service?.vehicle_instruction?.instruction_phase,
            },
            {
                name: "View",
                cell: (row: VendorVehicleJobDataTableReturnModel) => (
                    <IconButton
                        faIconString="fa fa-car"
                        variant="secondary"
                        tooltip="View Instruction"
                        url={`/vendor/instructions/${row.vehicle_id}`}
                    />
                ),
            },
            {
                name: "Actions",
                cell: (row: VendorVehicleJobDataTableReturnModel) => (
                    <VendorClosureActionCell reload={handleReload} vehicleJobId={row.vehicle_job_id} />
                ),
            },
        ],
        [handleReload]
    );

    const loadData = useCallback((data: VehicleJobSearchModel) => {
        return vendorVehicleJobService.searchVehicleJobs(data);
    }, []);

    useEffect(() => {
        setFilters({
            date_from: tryGetParam(params, "date_from"),
            date_to: tryGetParam(params, "date_to"),
            keyword: tryGetParam(params, "keyword"),
            overdue: tryGetParam(params, "overdue") === "true",
            out_of_time: tryGetParam(params, "out_of_time") === "true",
            vehicle_job_status_ids: getIntArrayFromParams(params, "vehicle_job_status_ids"),
            instruction_type_ids: getIntArrayFromParams(params, "instruction_type_ids"),
            consignment_status_ids: [ConsignmentStatus.Aborted, ConsignmentStatus.Completed],
        } as VehicleJobSearchModel);
    }, [params]);

    return (
        <>
            <h1 className="text-bold">Closure Review</h1>

            <G3DataTable load={loadData} columns={columns} filters={filters} />
        </>
    );
}
