import { G3Modal, IconButton, InfoTag } from "@g3r-developers/g3-common";
import { VehicleJobModel } from "@g3r-developers/vamp-types/dist/VehicleJob/VehicleJobModel";
import { InstructionHostileAgentClosureReason } from "Enums/InstructionHostileAgentClosureReason";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "reactstrap";
import vendorVehicleJobService from "Services/Vendor/VendorVehicleJobService";
import { ReturnOfGoodsRequiredForm } from "./ClosureForms/ReturnOfGoodsRequiredForm";
import { ScrappedWrittenOffForm } from "./ClosureForms/ScrappedWrittenOffForm";
import { TraceCompletedForm } from "./ClosureForms/TraceCompletedForm";
import { ReallocateServiceForm } from "./ReallocateServiceForm";

interface VendorClosureModalProps {
    reload: () => void;
    toggle: () => void;
    vehicleJobId: number;
}

export function VendorClosureModal({ reload, toggle, vehicleJobId }: VendorClosureModalProps) {
    const [vehicleJob, setVehicleJob] = useState<VehicleJobModel>(undefined);

    useEffect(() => {
        vendorVehicleJobService.getById(vehicleJobId).then(setVehicleJob);
    }, [vehicleJobId]);

    const closureForm = useMemo(() => {
        if (!vehicleJob) {
            return;
        }

        switch (vehicleJob.vehicle_job_outcome_id) {
            case InstructionHostileAgentClosureReason.TraceCompleted: {
                return <TraceCompletedForm vehicleJob={vehicleJob} toggle={toggle} />;
            }
            case InstructionHostileAgentClosureReason.ScrappedWrittenOff: {
                return <ScrappedWrittenOffForm vehicleJob={vehicleJob} />;
            }
            case InstructionHostileAgentClosureReason.ReturnOfGoodsRequired: {
                return <ReturnOfGoodsRequiredForm vehicleJob={vehicleJob} />;
            }
        }
    }, [vehicleJob, toggle]);

    const finishedOutcomes = useMemo(() => {
        return [
            InstructionHostileAgentClosureReason.VehicleCollected,
            InstructionHostileAgentClosureReason.MoneyCollectedFullArrears,
            InstructionHostileAgentClosureReason.MoneyCollectedPartArrears,
            InstructionHostileAgentClosureReason.MoneyCollectedPartArrears,
        ];
    }, []);

    const canReallocate = useMemo(() => {
        if (!vehicleJob) {
            return false;
        }

        return !finishedOutcomes.includes(vehicleJob.vehicle_job_outcome_id);
    }, [finishedOutcomes, vehicleJob]);

    const handleClose = useCallback(() => {}, []);

    return (
        <>
            {vehicleJob && (
                <G3Modal title={"Closure Review"} toggle={toggle} showSave={false}>
                    <Row>
                        <Col md="12">
                            <InfoTag title="Closure Reason" value={vehicleJob.vehicle_job_outcome} />
                        </Col>
                        {vehicleJob.vehicle_job_closure?.contact_who_requested_closure && (
                            <Col md="12">
                                <InfoTag
                                    title="Contact Who Requested Closure"
                                    value={vehicleJob.vehicle_job_closure?.contact_who_requested_closure.full_name}
                                />
                            </Col>
                        )}
                    </Row>

                    {closureForm}

                    <Row className="mt-3">
                        <Col md="12" className="mb-3">
                            <IconButton text="Close Out" variant="primary" onClick={handleClose} block />
                        </Col>
                    </Row>

                    {canReallocate && (
                        <>
                            <hr />
                            <h2 className="text-bold">Reallocate</h2>
                            <ReallocateServiceForm reload={reload} toggle={toggle} vehicleJob={vehicleJob} />
                        </>
                    )}
                </G3Modal>
            )}
        </>
    );
}
