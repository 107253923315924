import { VendorConsignmentSearchModel } from "@g3r-developers/vamp-types/dist/Consignment/VendorConsignmentSearchModel";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getIntArrayFromParams, getUrlParams, tryGetParam } from "Services/Utils";
import VendorConsignmentFilters from "./Consignments/VendorConsignmentFilters";
import VendorConsignmentsTable from "./Consignments/VendorConsignmentsTable";

export default function VendorConsignments() {
    const navigate = useNavigate();
    const { search } = useLocation();
    const params = useMemo(() => new URLSearchParams(search), [search]);
    const [filters, setFilters] = useState<VendorConsignmentSearchModel>({
        keyword: tryGetParam(params, "keyword"),
        consignment_status_ids: getIntArrayFromParams(params, "consignment_status_ids"),
        date_allocated_from: tryGetParam(params, "date_allocated_from"),
        date_allocated_to: tryGetParam(params, "date_allocated_to"),
        instruction_type_ids: getIntArrayFromParams(params, "instruction_type_ids"),
        service_ids: getIntArrayFromParams(params, "service_ids"),
    } as VendorConsignmentSearchModel);

    useEffect(() => {
        const searchParams = getUrlParams(filters);

        navigate(
            {
                search: `${searchParams.toString()}`,
            },
            {
                replace: true,
            }
        );
    }, [filters, navigate]);

    return (
        <>
            <VendorConsignmentFilters className={"mb-3"} filters={filters} setFilters={setFilters} />

            <VendorConsignmentsTable filters={filters} />
        </>
    );
}
