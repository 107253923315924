import { DataTableResult } from "@g3r-developers/vamp-types/dist/data-table-result";
import { MediaModel } from "@g3r-developers/vamp-types/dist/media/media-model";
import { PagingModel } from "@g3r-developers/vamp-types/dist/paging-model";
import { VehicleInstructionFeeModel } from "@g3r-developers/vamp-types/dist/vehicle/Fee/VehicleInstructionFeeModel";
import { ReallocateVehicleInstructionModel } from "@g3r-developers/vamp-types/dist/VehicleInstruction/ReallocateVehicleInstructionModel";
import { SearchVehicleInstructionServiceMapMarkerModel } from "@g3r-developers/vamp-types/dist/VehicleInstructionService/SearchVehicleInstructionServiceMapMarkerModel";
import { VehicleInstructionServiceMapMarkerModel } from "@g3r-developers/vamp-types/dist/VehicleInstructionService/VehicleInstructionServiceMapMarkerModel";
import Api from "../Api";

const BaseUrl = "vendor/instruction";

const fees = async (
    vehicleInstructionId: number,
    model: PagingModel
): Promise<DataTableResult<VehicleInstructionFeeModel>> => {
    return Api.get<DataTableResult<VehicleInstructionFeeModel>>(`${BaseUrl}/${vehicleInstructionId}/fees`, model);
};

const getInstructionMapMarkers = (
    model: SearchVehicleInstructionServiceMapMarkerModel
): Promise<VehicleInstructionServiceMapMarkerModel[]> => {
    return Api.get<VehicleInstructionServiceMapMarkerModel[]>(`${BaseUrl}/map`, model);
};

const reallocate = async (vehicleId: number, model: ReallocateVehicleInstructionModel): Promise<void> => {
    return Api.post<void>(`${BaseUrl}/${vehicleId}/reallocate`, model);
};

const uploadCreditAgreement = async (vehicleInstructionId: number, creditAgreement: File): Promise<void> => {
    return Api.postFile(`${BaseUrl}/${vehicleInstructionId}/credit-agreement`, creditAgreement, {});
};

const getInstructionMediaByCategory = (vehicleInstructionId: number, mediaCategoryId: number): Promise<MediaModel> => {
    return Api.get(`${BaseUrl}/${vehicleInstructionId}/Media/${mediaCategoryId}`);
};

const vendorInstructionService = {
    fees,
    getInstructionMapMarkers,
    getInstructionMediaByCategory,
    reallocate,
    uploadCreditAgreement,
};

export default vendorInstructionService;
