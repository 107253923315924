import { DataTableResult } from "@g3r-developers/vamp-types/dist/data-table-result";
import { PreRecoverySearchModel } from "@g3r-developers/vamp-types/dist/PreRecoverySearch/PreRecoverySearchModel";
import { VehicleJobModel } from "@g3r-developers/vamp-types/dist/VehicleJob/VehicleJobModel";
import { VendorVehicleJobDataTableReturnModel } from "@g3r-developers/vamp-types/dist/VehicleJob/VendorVehicleJobDataTableReturnModel";
import { VehicleJobSearchModel } from "Pages/Vendor/Instruction/Closure/VendorClosureReviewPage";
import * as Api from "../Api";

const BaseUrl = "vendor/vehicle-job";

export const getById = async (vehicleJobId: number): Promise<VehicleJobModel> => {
    return Api.get<VehicleJobModel>(`${BaseUrl}/${vehicleJobId}`);
};

export const getPreRecoverySearchByVehicleJobId = async (vehicleJobId: number): Promise<PreRecoverySearchModel> => {
    return Api.get<PreRecoverySearchModel>(`${BaseUrl}/${vehicleJobId}/pre-recovery-search`);
};

export const searchVehicleJobs = async (
    data: VehicleJobSearchModel
): Promise<DataTableResult<VendorVehicleJobDataTableReturnModel>> => {
    return Api.get<DataTableResult<VendorVehicleJobDataTableReturnModel>>(BaseUrl, data);
};

const vendorVehicleJobService = {
    getById,
    getPreRecoverySearchByVehicleJobId,
    searchVehicleJobs,
};

export default vendorVehicleJobService;
