import { VendorAuctionStatsModel } from "@g3r-developers/vamp-types/dist/Vendor/Auction/VendorAuctionStatsModel";
import useERecoveryToasts from "Hooks/UseERecoveryToasts";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Badge, Nav, NavItem, NavLink } from "reactstrap";
import vendorAuctionService from "Services/Vendor/VendorAuctionService";
import { InspectionsTab } from "./Tabs/InspectionsTab";
import { ProvisionalsTab } from "./Tabs/ProvisionalsTab";
import { RepairsTab } from "./Tabs/RepairsTab";
import { ReservesTab } from "./Tabs/ReservesTab";
import { VehiclesTab } from "./Tabs/VehiclesTab";

enum DisplayType {
    Vehicles = "Vehicles",
    Inspections = "Inspections",
    Reserves = "Reserves",
    Repairs = "Refurbs",
    Provisionals = "Provisional Bids",
}

export default function VendorAuctionPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const { errorToast } = useERecoveryToasts();

    const tabs = [
        DisplayType.Vehicles,
        DisplayType.Inspections,
        DisplayType.Reserves,
        DisplayType.Repairs,
        DisplayType.Provisionals,
    ];

    const getActiveHash = useCallback(() => {
        const hash = location.hash;

        if (!hash) {
            return DisplayType.Vehicles;
        }

        return decodeURI(hash.substring(1));
    }, [location]);

    const activeDisplay = getActiveHash();

    const [badgeStats, setBadgeStats] = useState<VendorAuctionStatsModel>(undefined);

    const getAuctionStats = useCallback(() => {
        vendorAuctionService.getAuctionStats().then(setBadgeStats).catch(errorToast);
    }, [errorToast]);

    useEffect(() => {
        getAuctionStats();
    }, [getAuctionStats]);

    const isActive = useCallback(
        (tab: string) => {
            return tab === activeDisplay ? "active" : "";
        },
        [activeDisplay]
    );

    const toggleActiveDisplay = useCallback(
        (newDisplay: string) => {
            navigate({
                hash: newDisplay,
            });
        },
        [navigate]
    );

    const getBadgeValue = useCallback(
        (t: string) => {
            if (!badgeStats) {
                return 0;
            }

            const indexOfS = Object.values(DisplayType).indexOf(t as unknown as DisplayType);
            const key = Object.keys(DisplayType)[indexOfS];

            return badgeStats[key.toLowerCase()];
        },
        [badgeStats]
    );

    return (
        <>
            <h1 className="text-bold">Auctions</h1>
            <Nav tabs>
                {tabs.map(t => (
                    <NavItem key={t}>
                        <NavLink className={isActive(t)} onClick={() => toggleActiveDisplay(t)}>
                            <div>
                                {t}{" "}
                                <Badge color="primary" className="w-auto">
                                    <strong>{getBadgeValue(t)}</strong>
                                </Badge>
                            </div>
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>
            {activeDisplay === DisplayType.Vehicles && <VehiclesTab />}
            {activeDisplay === DisplayType.Inspections && <InspectionsTab />}
            {activeDisplay === DisplayType.Reserves && <ReservesTab reloadStats={getAuctionStats} />}
            {activeDisplay === DisplayType.Repairs && <RepairsTab reloadStats={getAuctionStats} />}
            {activeDisplay === DisplayType.Provisionals && <ProvisionalsTab reloadStats={getAuctionStats} />}
        </>
    );
}
