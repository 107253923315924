import { G3DataTable, G3Dropdown, G3Input, G3Toggle, IconButton } from "@g3r-developers/g3-common";
import { VendorVehicleJobDataTableReturnModel } from "@g3r-developers/vamp-types/dist/VehicleJob/VendorVehicleJobDataTableReturnModel";
import VulnerableCell from "Components/Common/VulnerableCell";
import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import DropdownService from "Services/DropdownService";
import { getIntArrayFromParams, getUrlParams, tryGetParam } from "Services/Utils";
import vendorVehicleJobService from "Services/Vendor/VendorVehicleJobService";
import { VehicleJobSearchModel } from "./Closure/VendorClosureReviewPage";

export default function VendorVehicleJobsPage() {
    const { search } = useLocation();
    const navigate = useNavigate();
    const params = useMemo(() => new URLSearchParams(search), [search]);

    const filters = useMemo(() => {
        return {
            e_recovery_service_ids: getIntArrayFromParams(params, "e_recovery_service_ids"),
            date_from: tryGetParam(params, "date_from"),
            date_to: tryGetParam(params, "date_to"),
            keyword: tryGetParam(params, "keyword"),
            overdue: tryGetParam(params, "overdue") === "true",
            out_of_time: tryGetParam(params, "out_of_time") === "true",
            vehicle_job_status_ids: getIntArrayFromParams(params, "vehicle_job_status_ids"),
            instruction_type_ids: getIntArrayFromParams(params, "instruction_type_ids"),
        } as VehicleJobSearchModel;
    }, [params]);

    const columns = useMemo(
        () => [
            {
                name: "Agreement No.",
                selector: (row: VendorVehicleJobDataTableReturnModel) => row.agreement_num,
            },
            {
                name: "Registration",
                selector: "vrm",
            },
            {
                name: "Customer Name",
                cell: (row: VendorVehicleJobDataTableReturnModel) =>
                    row.vehicle_instruction_service?.vehicle_instruction?.customer_contact?.full_name ?? "N/A",
            },
            {
                name: "Closure Date",
                selector: (row: VendorVehicleJobDataTableReturnModel) => row.closure_date_string,
            },
            {
                name: "Service Required",
                selector: "vehicle_job_type",
            },
            {
                name: "Vulnerable",
                cell: (row: VendorVehicleJobDataTableReturnModel) => (
                    <VulnerableCell vulnerable={row.vehicle_instruction_service?.vehicle_instruction?.vulnerable} />
                ),
                center: true,
            },
            {
                name: "Status",
                selector: (row: VendorVehicleJobDataTableReturnModel) => row.vehicle_job_status,
            },
            {
                name: "Instruction Phase",
                cell: (row: VendorVehicleJobDataTableReturnModel) =>
                    row.vehicle_instruction_service?.vehicle_instruction?.instruction_phase,
            },
            {
                name: "View",
                cell: (row: VendorVehicleJobDataTableReturnModel) => (
                    <IconButton
                        faIconString="fa fa-car"
                        variant="secondary"
                        tooltip="View Instruction"
                        url={`/vendor/instructions/${row.vehicle_id}`}
                    />
                ),
            },
        ],
        []
    );

    const loadData = useCallback((data: any) => {
        return vendorVehicleJobService.searchVehicleJobs(data);
    }, []);

    const changeUrl = useCallback(
        (newFilters: VehicleJobSearchModel) => {
            const search = getUrlParams(newFilters);
            navigate(
                {
                    search: `${search.toString()}`,
                },
                { replace: true }
            );
        },
        [navigate]
    );

    const handleKeywordChanged = useCallback(
        (newVal: string) => {
            changeUrl({ ...filters, keyword: newVal });
        },
        [filters, changeUrl]
    );

    const handleVehicleJobStatusChanged = useCallback(
        (newVal: number[]) => {
            changeUrl({ ...filters, vehicle_job_status_ids: newVal });
        },
        [filters, changeUrl]
    );

    const handleERecoveryServiceChanged = useCallback(
        (newVal: number[]) => {
            changeUrl({ ...filters, e_recovery_service_ids: newVal });
        },
        [filters, changeUrl]
    );

    const handleInstructionTypeChanged = useCallback(
        (newVal: number[]) => {
            changeUrl({ ...filters, instruction_type_ids: newVal });
        },
        [filters, changeUrl]
    );

    const handleOverdueToggled = useCallback(
        (newVal: boolean) => {
            changeUrl({ ...filters, overdue: newVal });
        },
        [filters, changeUrl]
    );

    const handleOutOfTimeToggled = useCallback(
        (newVal: boolean) => {
            changeUrl({ ...filters, out_of_time: newVal });
        },
        [filters, changeUrl]
    );

    return (
        <>
            <h1 className="text-bold">Instructions - Services</h1>

            <Row>
                <Col>
                    <G3Input type="text" value={filters.keyword} label="Keyword" onChange={handleKeywordChanged} />
                </Col>
                <Col>
                    <G3Dropdown
                        loadOptions={(val: string) => DropdownService.attribvals("e_recovery_service", val)}
                        defaultOption={filters.e_recovery_service_ids}
                        label="Service"
                        multiple
                        onChange={handleERecoveryServiceChanged}
                    />
                </Col>
                <Col>
                    <G3Dropdown
                        loadOptions={(val: string) => DropdownService.attribvals("vehicle_job_status", val)}
                        defaultOption={filters.vehicle_job_status_ids}
                        label="Service Status"
                        multiple
                        onChange={handleVehicleJobStatusChanged}
                    />
                </Col>
                <Col>
                    <G3Dropdown
                        loadOptions={(val: string) => DropdownService.attribvals("transport_instruction_type", val)}
                        defaultOption={filters.instruction_type_ids}
                        label="Instruction Type"
                        multiple
                        onChange={handleInstructionTypeChanged}
                    />
                </Col>

                <Col>
                    <G3Toggle label="Overdue" active={filters.overdue} onToggle={handleOverdueToggled} />
                </Col>

                <Col>
                    <G3Toggle label="Out of Time" active={filters.out_of_time} onToggle={handleOutOfTimeToggled} />
                </Col>
            </Row>

            <br />

            <G3DataTable load={loadData} columns={columns} filters={filters} />
        </>
    );
}
