import { AuctionSaleCreateModel } from "@g3r-developers/vamp-types/dist/Auction/AuctionSaleCreateModel";
import { AuctionLotCreateModel } from "@g3r-developers/vamp-types/dist/Auction/AuctionLot/AuctionLotCreateModel";
import { AuctionSaleListModel } from "@g3r-developers/vamp-types/dist/Auction/AuctionSaleListModel";
import { AuctionSaleModel } from "@g3r-developers/vamp-types/dist/Auction/AuctionSaleModel";
import { AuctionSaleSearchModel } from "@g3r-developers/vamp-types/dist/Auction/AuctionSaleSearchModel";
import { DataTableResult } from "@g3r-developers/vamp-types/dist/data-table-result";
import Api from "../Api";

const BaseUrl = "Supplier/Auction/Sale";

const create = async (model: AuctionSaleCreateModel): Promise<AuctionSaleModel> => {
    return Api.post<AuctionSaleModel>(`${BaseUrl}`, model);
};

const _delete = async (auctionSaleId: number): Promise<void> => {
    return Api._delete<void>(`${BaseUrl}/${auctionSaleId}`);
};

const get = async (auctionSaleId: number): Promise<AuctionSaleModel> => {
    return Api.get<AuctionSaleModel>(`${BaseUrl}/${auctionSaleId}`);
};

const search = async (model: AuctionSaleSearchModel): Promise<DataTableResult<AuctionSaleListModel>> => {
    return Api.get<DataTableResult<AuctionSaleListModel>>(`${BaseUrl}`, model);
};

const lot = async (model: AuctionLotCreateModel) => {
    return Api.post(`Auction/Vehicle/sale/lot`, model);
};

const unlot = async (vehicleId: number) => {
    return Api.patch(`Auction/Vehicle/${vehicleId}/unlot`, {});
};

const auctionSaleService = {
    create,
    _delete,
    get,
    lot,
    search,
    unlot,
};

export default auctionSaleService;
