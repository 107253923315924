import "@g3r-developers/g3-common/dist/main.css";
import { LoginResponse } from "@g3r-developers/vamp-types/dist/User/LoginResponse";
import { Suspense } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { Container } from "reactstrap";
import Routes from "Routes/Routes";
import "./App.scss";

interface AppProps {
    authenticated: boolean;
    checked: boolean;
    user: LoginResponse;
}

const App = ({ authenticated, checked, user }: AppProps) => (
    <Router>
        {checked && (
            <Suspense fallback={<div>Loading...</div>}>
                <Container className={"h-100 px-0"}>
                    <Routes />
                </Container>
            </Suspense>
        )}
    </Router>
);

const mapState = ({ session }) => ({
    authenticated: session?.authenticated ?? false,
    checked: session?.checked ?? false,
    user: session?.user ?? {},
});

export default connect(mapState)(App);
