import { DataTableResultModel } from "@g3r-developers/vamp-types/dist/DataTableResultModel";
import { VehicleMediaMarketingResponse } from "@g3r-developers/vamp-types/dist/media/vehicle-media-marketing.res";
import { TransportModel } from "@g3r-developers/vamp-types/dist/Transport/TransportModel";
import { CreateImportModel } from "@g3r-developers/vamp-types/dist/vehicle/CreateImportModel";
import { CreateVehicleModel } from "@g3r-developers/vamp-types/dist/vehicle/CreateVehicleModel";
import { CreateImportResponse } from "@g3r-developers/vamp-types/dist/vehicle/import/CreateImportResponse";
import { CsvImportDTModel } from "@g3r-developers/vamp-types/dist/vehicle/import/CsvImportDtModel";
import { ImportResult } from "@g3r-developers/vamp-types/dist/vehicle/import/ImportResult";
import { ProcessImportRequestModel } from "@g3r-developers/vamp-types/dist/vehicle/import/ProcessImportRequestModel";
import { TerminationNoticeImportResult } from "@g3r-developers/vamp-types/dist/vehicle/import/TerminationNoticeImportResult";
import { OutstandingRecoveriesReturnModel } from "@g3r-developers/vamp-types/dist/vehicle/OutstandingRecoveriesReturnModel";
import { OutstandingRecoveriesSearchModel } from "@g3r-developers/vamp-types/dist/vehicle/OutstandingRecoveriesSearchModel";
import { ReleaseVehicleHoldModel } from "@g3r-developers/vamp-types/dist/vehicle/ReleaseVehicleHoldModel";
import { SetVehicleOnHoldModel } from "@g3r-developers/vamp-types/dist/vehicle/SetVehicleOnHoldModel";
import { VehicleListModel } from "@g3r-developers/vamp-types/dist/vehicle/vehicle-list-model";
import { VehicleModel } from "@g3r-developers/vamp-types/dist/vehicle/VehicleModel";
import { VehicleSearchModel } from "@g3r-developers/vamp-types/dist/vehicle/VehicleSearchModel";
import { VehicleVendorReturnModel } from "@g3r-developers/vamp-types/dist/vehicle/VehicleVendorReturnModel";
import { VehicleVendorUserReturnModel } from "@g3r-developers/vamp-types/dist/vehicle/VehicleVendorUserReturnModel";
import { VendorVehicleDataTableReturnModel } from "@g3r-developers/vamp-types/dist/Vendor/Vehicle/VendorVehicleDataTableReturnModel";
import Api from "../Api";

const BASE_URL = "vendor/vehicle";

const create = (data: CreateVehicleModel): Promise<CreateImportResponse> => {
    return createImport({
        vehicles: [data],
    });
};

const createImport = (data: CreateImportModel): Promise<CreateImportResponse> => {
    return Api.post(`${BASE_URL}`, data);
};

const processImportRow = async (rowId: number): Promise<ImportResult> => {
    return Api.post(`${BASE_URL}/import/process`, { row_ids: [rowId] } as ProcessImportRequestModel).then(resp => {
        return resp[0];
    });
};

const importTerminationNotice = async (file: File): Promise<TerminationNoticeImportResult> => {
    return Api.postFile(`${BASE_URL}/import/termination-notice`, file);
}

const getImportById = (importId: number): Promise<CsvImportDTModel> => {
    return Api.get(`${BASE_URL}/import/${importId}`);
};

const get = (vehicleId: number): Promise<VehicleModel> => {
    return Api.get<VehicleModel>(`${BASE_URL}/${vehicleId}`);
};

const getMileages = async (vehicleId: number): Promise<VehicleListModel[]> => {
    return Api.get(`${BASE_URL}/${vehicleId}/Mileages`);
};

const getOutstandingRecoveries = (
    data: OutstandingRecoveriesSearchModel
): Promise<DataTableResultModel<OutstandingRecoveriesReturnModel>> => {
    return Api.get(`${BASE_URL}/outstanding`, data);
};

const getTransports = async (vehicleId: number): Promise<TransportModel[]> => {
    return Api.get<TransportModel[]>(`${BASE_URL}/${vehicleId}/transports`);
};

const getVehicleImages = (vehicleId: number, categories?: string[]): Promise<VehicleMediaMarketingResponse> => {
    return Api.get(`${BASE_URL}/images/${vehicleId}`, { categories: categories });
};

const getVehicles = (data: VehicleSearchModel): Promise<DataTableResultModel<VehicleVendorUserReturnModel>> => {
    return Api.get(`${BASE_URL}`, data);
};

const putOnHold = async (vehicleId: number, model: SetVehicleOnHoldModel): Promise<VehicleVendorReturnModel> => {
    return Api.patch<VehicleVendorReturnModel>(`${BASE_URL}/${vehicleId}/on-hold`, model);
};

const releaseFromHold = async (
    vehicleId: number,
    model: ReleaseVehicleHoldModel
): Promise<VehicleVendorReturnModel> => {
    return Api.patch<VehicleVendorReturnModel>(`${BASE_URL}/${vehicleId}/release-hold`, model);
};

const searchVehicles = (data: VehicleSearchModel): Promise<DataTableResultModel<VendorVehicleDataTableReturnModel>> => {
    return Api.get(`${BASE_URL}`, data);
};

const vendorVehicleService = {
    create,
    createImport,
    get,
    getImportById,
    getMileages,
    getOutstandingRecoveries,
    getTransports,
    getVehicleImages,
    getVehicles,
    importTerminationNotice,
    processImportRow,
    putOnHold,
    releaseFromHold,
    searchVehicles,
};

export default vendorVehicleService;
