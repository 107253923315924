export const isEmpty = (obj: object): boolean => {
    return !obj || !Object.keys(obj).length;
};

export const isUndefinedOrWhitespace = (value?: string) => {
    return !value || !value.trim();
};

export const getIntArrayFromParams = (params: URLSearchParams, key: string): number[] => {
    const item = params.get(key);

    if (!item) {
        return [];
    }

    return item.split(",").map(function (i) {
        return parseInt(i);
    });
};

export const getUrlParams = (obj: unknown): URLSearchParams => {
    const params = new URLSearchParams();

    Object.keys(obj).forEach(k => {
        if (obj[k] !== undefined && obj[k] !== "" && obj[k].length !== 0) {
            params.set(k, obj[k]);
        }
    });

    return params;
};

export const titleCase = (str: string): string => {
    const splitStr = str.toLowerCase().split(" ");

    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].slice(1);
    }

    return splitStr.join(" ");
};

export const tryGetParam = (params: URLSearchParams, key: string) => {
    const value = params.get(key);

    if (!value) {
        return undefined;
    }
    return value;
};

export const paramsToObject = (params: URLSearchParams) => {
    return Object.fromEntries(params);
};

// This is meant to be a "loose" comparison to check for null as well
// eslint-disable-next-line eqeqeq
export const getPercentString = (value: number) => (value == undefined ? "N/A" : `${value}%`);

export const nameofFactory =
    <T>() =>
    (name: keyof T) =>
        name;

export const nameof = <T>(name: keyof T) => name;

export interface DataGridItem {
    label?: string;
    spacer?: boolean;
    value?: number | string;
}

export interface DataGridRow {
    id: string;
    items: DataGridItem[];
}
