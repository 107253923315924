export enum MediaCategory {
    Unknown = 2240,
    Damage = 2241,
    Marketing = 2242,
    DocumentScan = 2513,
    VendorPdf = 2797,
    PurchaserPdf = 2798,
    AssuredPdf = 2799,
    Internal360 = 2800,
    Pod = 2850,
    V5 = 2860,
    History = 2861,
    LooseItems = 2863,
    Invoice = 2865,
    _360 = 2910,
    Interior360 = 2914,
    NoContactLetter = 2961,
    Logo = 2963,
    CompanyDocument = 2977,
    CompoundInspectionReport = 3080,
    IndemnityNotice = 3085,
    DvlaReceipt = 3086,
    InsuranceCoverNote = 3087,
    SeizureNotice = 3088,
    FinanceHouseReleaseNotice = 3089,
    AuthorityToCollectionInstruction = 3090,
    LegalFinanceAgreement = 3091,
    VehicleCondition = 3092,
    VehicleList = 3093,
    Insurance = 3103,
    CompanyHouseAccounts = 3141,
    ContractOfEngagement = 3142,
    GdprAssessment = 3143,
    SupplierDueDiligenceQuestionnaire = 3144,
    VehicleRefurb = 3181,
    PurchaserInvoice = 3221,
    Grievance = 3357,
    BuyerApplication = 3418,
    CancelInstructionEmail = 3519,
    VehicleFineMedia = 3537,
    Remittance = 3540,
    Sorn = 3577,
    CreditNote = 3579,
    ProfilePicture = 3606,
    VehicleInstructionMedia = 3640,
    VehicleVinImage = 3661,
    AudioDamageCommentary = 3673,
    VehiclePersonalItems = 3708,
    V62ApplicationLetter = 3712,
    FeeRequestEvidence = 3781,
    TraceReport = 3801,
    DriveByEvidence = 3802,
    InstructionCreditAgreement = 3803,
    VehicleInspectionReport = 3814,
    SignedVSLetter = 3815,
    ClosureReport = 3816,
    NewKeeperQuestionnaire = 3818,
    TerminationNotice = 3819,
    ExperianHPIEvidence = 3871,
    EvidenceOfVehicle = 3872,
    ClosureRequest = 3901,
    AgentCollectionReport = 3902,
    AvidVendorPdf = 2797,
    AvidPurchaserPdf = 2798,
    AvidAssuredPdf = 2799,
    Exterior360 = 2910,
    SignedVsLetter = 3815,
    PaveInspectionPdf = 3817,
    CoshhAssessment = 3852,
    ChemicalFactSheet = 3853,
    SupplierCreditReport = 3854,
    ExperianHpiEvidence = 3871,
}
