import VehicleFeeResponsePage from "Pages/Supplier/VehicleFees/VehicleFeeResponsePage";
import { lazy } from "react";
import { Route, Routes } from "react-router-dom";

export default function SupplierRoutes() {
    const ConsignmentExtensionResponsesPage = lazy(() => import("Pages/Supplier/ConsignmentExtensionResponsesPage"));
    const NotificationsPage = lazy(() => import("Pages/NotificationsPage"));
    const SupplierConsignmentArrangementsListPage = lazy(
        () => import("Pages/Supplier/SupplierConsignmentExtensionsListPage")
    );
    const SupplierHomePage = lazy(() => import("Pages/Supplier/SupplierHomePage"));
    const SupplierInstructionListPage = lazy(() => import("Pages/Supplier/SupplierInstructionListPage"));
    const SupplierInstructionMapPage = lazy(() => import("Pages/Supplier/SupplierInstructionMapPage"));
    const SupplierInstructionViewPage = lazy(() => import("Pages/Supplier/SupplierInstructionViewPage"));
    const ViewSupplierVehicleJobPage = lazy(() => import("Pages/Supplier/VehicleJobs/ViewSupplierVehicleJobPage"));

    return (
        <Routes>
            <Route element={<SupplierHomePage />} index />
            <Route element={<SupplierConsignmentArrangementsListPage />} path="/consignment-extensions" />
            <Route element={<ConsignmentExtensionResponsesPage />} path={"/consignment-extension-responses"} />
            <Route element={<ViewSupplierVehicleJobPage />} path="/jobs/:vehicleJobId" />
            <Route element={<SupplierInstructionListPage />} path={"/instructions"} />
            <Route element={<SupplierInstructionViewPage />} path={"/instructions/:vehicleInstructionId"} />
            <Route element={<NotificationsPage />} path={"/notifications"} />
            <Route element={<SupplierInstructionMapPage />} path="/instructions/map" />
            <Route element={<VehicleFeeResponsePage />} path="/fee-responses" />
        </Routes>
    );
}
