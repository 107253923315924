import { G3Input, IconButton, showconfirmmodal } from "@g3r-developers/g3-common";
import { GenericVehicleTaskReturnModel } from "@g3r-developers/vamp-types/dist/vehicle/task/generic-vehicle-task-return-model";
import useERecoveryToasts from "Hooks/UseERecoveryToasts";
import { useCallback, useState } from "react";
import vendorAuctionService from "Services/Vendor/VendorAuctionService";

interface ReserveInputCellProps {
    reload?(): void;
    reloadStats?(): void;
    row: GenericVehicleTaskReturnModel;
}

export default function ReserveInputCell({ reload, reloadStats, row }: ReserveInputCellProps) {
    const [reserve, setReserve] = useState<number>(row.reserve_price);
    const { successToast, errorToast } = useERecoveryToasts();

    const postReserve = useCallback(() => {
        vendorAuctionService
            .setReserve(row.vehicle_id, { reserve: reserve })
            .then(() => {
                reload && reload();
                reloadStats && reloadStats();
                successToast("Set Reserve successful");
            })
            .catch(errorToast);
    }, [errorToast, reload, reloadStats, reserve, row.vehicle_id, successToast]);

    const reserveConfirm = useCallback(
        (reserve: number, cc: number) => {
            showconfirmmodal(
                "Confirm",
                `Are you sure you want to set reserve price to ${reserve} when CAP Clean is ${cc}?`
            ).then(r => {
                if (r) {
                    postReserve();
                }
            });
        },
        [postReserve]
    );

    const handleSave = useCallback(() => {
        if (reserve % 25 !== 0) {
            errorToast("Please enter an increment of 25");
            return;
        }

        const cap = row.caps;

        if (!cap) {
            postReserve();
            return;
        }

        const cc = cap.value_trade_clean;

        if ((cc / 100) * 80 > reserve) {
            reserveConfirm(reserve, cc);
        } else {
            postReserve();
        }
    }, [errorToast, postReserve, reserve, reserveConfirm, row.caps]);

    return (
        <>
            <G3Input type="number" value={reserve ?? ""} onChange={(e: number) => setReserve(e)} />
            <IconButton className="ms-1" variant="secondary" faIconString="fa fa-save" onClick={handleSave} />
        </>
    );
}
