import { RejectProvisionalModel } from "@g3r-developers/vamp-types/dist/bid/RejectProvisionalModel";
import { DataTableResultModel } from "@g3r-developers/vamp-types/dist/DataTableResultModel";
import { ProvisionalSearchReturnModel } from "@g3r-developers/vamp-types/dist/sale/ProvisionalSearchReturnModel";
import { VehicleTaskSearchModel } from "@g3r-developers/vamp-types/dist/vehicle/task/VehicleTaskSearchModel";
import Api from "../Api";
const BASE_URL = "vendor/auction";

const accept = async (bidId: number): Promise<boolean> => {
    return Api.post(`${BASE_URL}/provisional-bid/${bidId}/accept`);
};

const getProvisionalBids = (
    search: VehicleTaskSearchModel
): Promise<DataTableResultModel<ProvisionalSearchReturnModel>> => {
    return Api.get(`${BASE_URL}/provisional-bid`, search);
};

const reject = async (bidId: number, counterOffer: RejectProvisionalModel): Promise<boolean> => {
    return Api.post(`${BASE_URL}/provisional-bid/${bidId}/reject`, counterOffer);
};

const provisionalService = {
    accept,
    getProvisionalBids,
    reject,
};

export default provisionalService;
