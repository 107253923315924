import { Badge } from "reactstrap";

export default function VulnerableCell({ vulnerable }) {
    return (
        <>
            {vulnerable && <Badge color="danger">YES</Badge>}
            {!vulnerable && <span>NO</span>}
        </>
    );
}
