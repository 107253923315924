import NotificationsSideNavLink from "./NotificationsSideNavLink";
import { SideNavLinkProps } from "./SideNavLink/SideNavLink";

const supplierLinks: SideNavLinkProps[] = [
    {
        faIconString: "fas fa-home",
        text: "Dashboard",
        to: "/supplier",
    },
    {
        component: (
            <NotificationsSideNavLink
                faIconString={"fas fa-bell"}
                text={"Notifications"}
                to={"/supplier/notifications"}
            />
        ),
        to: "/supplier/notifications",
    },
    {
        faIconString: "fas fa-clipboard-list",
        text: "Instructions",
        to: "/supplier/instructions",
        children: [
            {
                text: "Dashboard",
                to: "/supplier/instructions",
                faIconString: "",
            },
            {
                text: "Map",
                to: "/supplier/instructions/map",
                faIconString: "",
            },
        ],
    },
];

export default supplierLinks;
