import { useMemo } from "react";
import { Col, Row } from "reactstrap";

export interface PageTitleProps {
    className?: string;
    title: string;
    actions?: JSX.Element | JSX.Element[];
}

export default function PageTitle({ className, title, actions }: PageTitleProps) {
    const classes = useMemo(() => {
        let classString = "mt-3";

        if (className) {
            if (className.includes("mt-")) {
                classString = "";
            }

            classString += ` ${className}`;
        }

        return classString;
    }, [className]);

    return (
        <Row className={classes}>
            <Col>
                <h1 className="text-bold">
                    {title}
                    {actions && <div className="clearfix float-right">{actions}</div>}
                </h1>
            </Col>
        </Row>
    );
}
