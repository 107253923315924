import VendorConsignments from "Components/Vendor/VendorConsignments";
import PageTitle from "Pages/PageTitle";
import React from "react";

export default function VendorConsignmentsPage() {
    return (
        <>
            <PageTitle className={"mb-3"} title={"Consignments"} />

            <VendorConsignments />
        </>
    );
}
