import { DataTableResult } from "@g3r-developers/vamp-types/dist/data-table-result";
import { PagingModel } from "@g3r-developers/vamp-types/dist/paging-model";
import { UnacknowledgedVehicleFeeReturnModel } from "@g3r-developers/vamp-types/dist/vehicle/Fee/UnacknowledgedVehicleFeeReturnModel";
import * as Api from "../Api";

const BASE_URL = "supplier/vehicle-fee";

export const acknowledgeFee = (vehicleFeeId: number): Promise<boolean> => {
    return Api.patch(`${BASE_URL}/Acknowledge/${vehicleFeeId}`, {});
};

export const getUnacknowledgedVehicleFees = (
    model: PagingModel
): Promise<DataTableResult<UnacknowledgedVehicleFeeReturnModel>> => {
    return Api.get(`${BASE_URL}/Fee-responses`, model);
};

const supplierVehicleFeeService = {
    acknowledgeFee,
    getUnacknowledgedVehicleFees,
};

export default supplierVehicleFeeService;
