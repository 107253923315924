import { G3DataTable, IconButton } from "@g3r-developers/g3-common";
import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getIntArrayFromParams, getUrlParams, tryGetParam } from "Services/Utils";
import eAppraiseService from "Services/Vendor/EAppraiseService";
import { EAppraiseFilters } from "./EAppraiseFilters";
import { EAppraiseDataTableReturnModel } from "@g3r-developers/vamp-types/dist/EAppraise/EAppraiseDataTableReturnModel";

export interface EAppraiseSearchModel {
    vehicle_job_status_ids?: number[];
    keyword?: string;
    date_from?: string;
    date_to?: string;
}

export default function EAppraisePage() {
    const { search } = useLocation();
    const navigate = useNavigate();
    const params = useMemo(() => new URLSearchParams(search), [search]);

    const initialFilters = useMemo(() => {
        return {
            date_from: tryGetParam(params, "date_from"),
            date_to: tryGetParam(params, "date_to"),
            keyword: tryGetParam(params, "keyword"),
            vehicle_job_status_ids: getIntArrayFromParams(params, "vehicle_job_status_ids"),
        } as EAppraiseSearchModel;
    }, [params]);

    const columns = useMemo(
        () => [
            {
                selector: "vrm",
                name: "VRM",
            },
            {
                selector: "agreement_number",
                name: "Agreement No.",
            },
            {
                selector: "vehicle_job_status",
                name: "E-Appraise Status",
            },
            {
                selector: "date_issued_string",
                name: "Date Issued",
            },
            {
                cell: (row: EAppraiseDataTableReturnModel) => row.completed_details?.damage_grade ?? "TBC",
                name: "Grade",
            },
            {
                cell: (row: EAppraiseDataTableReturnModel) => row.completed_details?.damage_estimate ?? "TBC",
                name: "Damage Estimate",
            },
            {
                cell: (row: EAppraiseDataTableReturnModel) => (
                    <IconButton
                        faIconString="fa fa-car"
                        tooltip="View Instruction"
                        variant="secondary"
                        url={`/vendor/instructions/${row.vehicle_id}`}
                    />
                ),
                name: "View",
            },
        ],
        []
    );

    const loadDataTable = useCallback((data: any) => {
        return eAppraiseService.search(data);
    }, []);

    const changeUrl = useCallback(
        (newFilters: EAppraiseSearchModel) => {
            const search = getUrlParams(newFilters);
            navigate(
                {
                    search: `${search.toString()}`,
                },
                { replace: true }
            );
        },
        [navigate]
    );

    return (
        <>
            <h1 className="text-bold">E-Appraise</h1>

            <EAppraiseFilters filters={initialFilters} setFilters={changeUrl} />
            <br />
            <G3DataTable columns={columns} load={loadDataTable} filters={initialFilters} />
        </>
    );
}
