import { IconButton, showconfirmmodal } from "@g3r-developers/g3-common";
import { ProvisionalSearchReturnModel } from "@g3r-developers/vamp-types/dist/sale/ProvisionalSearchReturnModel";
import useERecoveryToasts from "Hooks/UseERecoveryToasts";
import { useCallback, useState } from "react";
import provisionalService from "Services/Vendor/ProvisionalService";
import RejectProvisionalBidModal from "./RejectProvisionalBidModal";

interface ProvisionalBidsActionCellProps {
    reload?(): void;
    reloadStats?(): void;
    row: ProvisionalSearchReturnModel;
}

export default function ProvisionalBidsActionCell({ reload, reloadStats, row }: ProvisionalBidsActionCellProps) {
    const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
    const { successToast, errorToast } = useERecoveryToasts();

    const toggleRejectModal = useCallback(() => {
        setShowRejectModal(!showRejectModal);
    }, [setShowRejectModal, showRejectModal]);

    const handleAccept = useCallback(async () => {
        let resp = false;
        if (row.bid_amount < row.reserve_price * 0.8) {
            resp = await showconfirmmodal(
                "Please confirm",
                `Are you sure you want to accept this bid? The bid on this vehicle is ${
                    (row.bid_amount / row.reserve_price) * 100
                }% of Reserve`
            );
        } else {
            resp = await showconfirmmodal("Please confirm", "Are you sure you want to accept this bid?");
        }

        if (resp) {
            provisionalService
                .accept(row.bid_id)
                .then(() => {
                    successToast("Your response was saved successfully");
                    reload();
                    reloadStats();
                })
                .catch(errorToast);
        }
    }, [errorToast, reload, reloadStats, row, successToast]);

    return (
        <>
            <IconButton
                className="me-1"
                faIconString="fas fa-check"
                onClick={handleAccept}
                tooltip="Accept"
                variant="primary"
            />
            <IconButton
                faIconString="fas fa-times"
                onClick={toggleRejectModal}
                tooltip="Reject"
                variant="secondary"
            />
            {showRejectModal && (
                <RejectProvisionalBidModal
                    close={toggleRejectModal}
                    reload={reload}
                    reloadStats={reloadStats}
                    row={row}
                />
            )}
        </>
    );
}
