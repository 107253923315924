import { IconButton } from "@g3r-developers/g3-common";
import { MediaModel } from "@g3r-developers/vamp-types/dist/media/media-model";
import { SupplierVehicleJobModel } from "@g3r-developers/vamp-types/dist/VehicleJob/SupplierVehicleJobModel";
import { MediaCategory } from "Enums/MediaCategory";
import { useEffect, useState } from "react";
import { Col, Label, Row } from "reactstrap";
import vendorInstructionService from "Services/Vendor/VendorInstructionService";

export interface ReturnOfGoodsRequiredFormProps {
    vehicleJob: SupplierVehicleJobModel;
}

export function ReturnOfGoodsRequiredForm({ vehicleJob }: ReturnOfGoodsRequiredFormProps) {
    const [evidenceOfVehicle, setEvidenceOfVehicle] = useState<MediaModel>(undefined);

    useEffect(() => {
        vendorInstructionService
            .getInstructionMediaByCategory(vehicleJob.vehicle_instruction.vehicle_id, MediaCategory.EvidenceOfVehicle)
            .then(setEvidenceOfVehicle);
    }, [vehicleJob.vehicle_instruction.vehicle_id]);

    return (
        <>
            <Row>
                <Col md="12">
                    <Label className="g3-label">Closure Specific Notes</Label>
                </Col>
                <Col md="12">
                    <textarea className="form-control" disabled value={vehicleJob.vehicle_job_closure?.closure_notes} />
                </Col>

                {evidenceOfVehicle && (
                    <Col md="12" className="mt-3">
                        <IconButton
                            block
                            variant="success"
                            className="text-white"
                            externalUrl={evidenceOfVehicle.url}
                            text="View Evidence of Vehicle"
                            targetString="_blank"
                        />
                    </Col>
                )}
            </Row>
        </>
    );
}
