import { IconButton, InfoTag } from "@g3r-developers/g3-common";
import { MediaModel } from "@g3r-developers/vamp-types/dist/media/media-model";
import { SupplierVehicleJobModel } from "@g3r-developers/vamp-types/dist/VehicleJob/SupplierVehicleJobModel";
import { useEffect, useState } from "react";
import { Col, Label, Row } from "reactstrap";
import vendorPreRecoverySearchService from "Services/Vendor/VendorPreRecoverySearchService";

export interface TraceCompletedFormProps {
    vehicleJob: SupplierVehicleJobModel;
    toggle(): void;
}

export function TraceCompletedForm({ vehicleJob, toggle }: TraceCompletedFormProps) {
    const [traceReport, setTraceReport] = useState<MediaModel>(undefined);

    useEffect(() => {
        vendorPreRecoverySearchService.getTraceReport(vehicleJob.pre_recovery_search_id).then(setTraceReport);
    }, [vehicleJob.pre_recovery_search_id]);

    return (
        <>
            <Row>
                <Col md="12">
                    <InfoTag
                        title="Trace Completed Successfully?"
                        value={vehicleJob.vehicle_job_closure?.successful ? "Yes" : "No"}
                    />
                </Col>
                <Col md="12">
                    <Label className="g3-label">Closure Specific Notes</Label>
                </Col>
                <Col md="12">
                    <textarea className="form-control" disabled value={vehicleJob.vehicle_job_closure?.closure_notes} />
                </Col>

                {traceReport && (
                    <Col md="12" className="mt-3">
                        <IconButton
                            block
                            variant="success"
                            className="text-white"
                            externalUrl={traceReport.url}
                            text="View Trace Report"
                            targetString="_blank"
                        />
                    </Col>
                )}
            </Row>
        </>
    );
}
