import LottableVehiclesTable from "Components/Auction/Lot/LottableVehiclesTable";
import PageTitle from "Pages/PageTitle";
import { Col, Row } from "reactstrap";

export default function AuctionLotVehiclesPage() {
    return (
        <>
            <Row>
                <Col>
                    <PageTitle title={"Lot Vehicles"} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <LottableVehiclesTable />
                </Col>
            </Row>
        </>
    );
}
