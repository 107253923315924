import authService from "./AuthService";

export const _delete = async <T extends unknown>(url: string): Promise<T> => {
    const apiUrl = `${process.env.REACT_APP_API_URL}${url}`;

    let finalApi = new URL(apiUrl);

    const options = await getOptions("DELETE");

    return fetch(finalApi.toString(), options).then(res => handleResponse<T>(res));
};

export const get = async <T extends unknown>(url: string, data?: any): Promise<T> => {
    const apiUrl = `${process.env.REACT_APP_API_URL}${url}`;

    let finalApi = new URL(apiUrl);

    if (data) {
        finalApi = applyQueryParams(finalApi, data);
    }

    const options = await getOptions("GET", data);

    return fetch(finalApi.toString(), options).then(res => handleResponse<T>(res));
};

export const patch = async <T extends unknown>(url: string, data: any): Promise<T> => {
    const apiUrl = `${process.env.REACT_APP_API_URL}${url}`;

    const finalApi = new URL(apiUrl);

    const options = await getOptions("PATCH", data);

    return fetch(finalApi.toString(), options).then(res => handleResponse<T>(res));
};

export const post = async <T extends unknown>(url: string, data?: any): Promise<T> => {
    const apiUrl = `${process.env.REACT_APP_API_URL}${url}`;

    const finalApi = new URL(apiUrl);

    const options = await getOptions("POST", data);

    return fetch(finalApi.toString(), options).then(res => handleResponse<T>(res));
};

export const put = async <T extends unknown>(url: string, data: any): Promise<T> => {
    const apiUrl = `${process.env.REACT_APP_API_URL}${url}`;

    const finalApi = new URL(apiUrl);

    const options = await getOptions("PUT", data);

    return fetch(finalApi.toString(), options).then(res => handleResponse<T>(res));
};

const buildFormData = (formData, data, parentKey?) => {
    if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? "" : data;

        formData.append(parentKey, value);
    }
};

export const postFile = async <T extends unknown>(url: string, fileToUpload: File, data?: any): Promise<T> => {
    const formData = new FormData();
    if (data) {
        buildFormData(formData, data);
    }
    formData.append("File", fileToUpload);

    const apiUrl = `${process.env.REACT_APP_API_URL}${url}`;

    const finalApi = new URL(apiUrl);

    let session = undefined;
    try {
        session = await authService.getUser();
    } catch {}

    const headers = {
        "X-G3-Platform": "2",
    };

    if (session && session.token) {
        headers["Authorization"] = `Bearer ${session.token}`;
    }

    const options = {
        headers: headers,
        method: "POST",
    } as RequestInit;

    options.body = formData;

    return fetch(finalApi.toString(), options).then(res => handleResponse<T>(res));
};

function applyQueryParams(url: URL, data: any): URL {
    const urlWithQueryParams = new URL(url.toString());

    Object.keys(data).forEach(key => {
        if (data[key] !== undefined) {
            if (Array.isArray(data[key])) {
                data[key].forEach((_: any) => {
                    urlWithQueryParams.searchParams.append(key, _);
                });
            } else {
                urlWithQueryParams.searchParams.append(key, data[key]);
            }
        }
    });

    return urlWithQueryParams;
}

async function getHeaders(): Promise<Record<string, string>> {
    let session = undefined;
    try {
        session = await authService.getUser();
    } catch {}

    const headers = {
        "Content-Type": "application/json",
        "X-G3-Platform": "2",
    };

    if (session && session.token) {
        headers["Authorization"] = `Bearer ${session.token}`;
    }

    return headers;
}

async function getOptions(method: string, data?: any): Promise<RequestInit> {
    const options = {
        headers: await getHeaders(),
        method: method,
    } as RequestInit;

    if (hasNoBody(method, data)) {
        return options;
    }

    options.body = JSON.stringify(data);

    return options;
}

async function handleResponse<T extends unknown>(response: Response): Promise<T> {
    if (!response.ok) {
        return response.text().then(text => {
            if (text === "") {
                return Promise.reject(response.statusText);
            }
            return Promise.reject(text);
        });
    }

    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);

        return data;
    });
}

function hasNoBody(method: string, data: any) {
    return !data || method === "GET";
}

const Api = {
    _delete,
    get,
    patch,
    post,
    put,
    postFile,
};

export default Api;
