import { VendorVehicleRepairSearchModel } from "@g3r-developers/vamp-types/dist/Vendor/Auction/VendorVehicleRepairSearchModel";
import RepairsFilter from "Components/Vendor/Auction/Repairs/RepairsFilter";
import RepairsTable from "Components/Vendor/Auction/Repairs/RepairsTable";
import { TaskStatus } from "Enums/TaskStatus";
import { useState } from "react";

interface RepairsTabProps {
    reloadStats?(): void;
}

export function RepairsTab({ reloadStats }: RepairsTabProps) {
    const [filters, setFilters] = useState<VendorVehicleRepairSearchModel>({
        vehicle_task_status_ids: [TaskStatus.Available],
    } as VendorVehicleRepairSearchModel);

    return (
        <>
            <RepairsFilter className="mt-2 mb-3" filters={filters} setFilters={setFilters} />
            <RepairsTable filters={filters} setFilters={setFilters} reloadStats={reloadStats} />
        </>
    );
}
