import { G3DataTable } from "@g3r-developers/g3-common";
import { VendorConsignmentModel } from "@g3r-developers/vamp-types/dist/Consignment/VendorConsignmentModel";
import { VendorConsignmentSearchModel } from "@g3r-developers/vamp-types/dist/Consignment/VendorConsignmentSearchModel";
import React, { useCallback, useMemo } from "react";
import vendorConsignmentService from "Services/Vendor/VendorConsignmentService";

export interface ConsignmentsTableProps {
    filters: VendorConsignmentSearchModel;
}

export default function VendorConsignmentsTable({ filters }: ConsignmentsTableProps) {
    const columns = useMemo(
        () => [
            {
                selector: (row: VendorConsignmentModel) => row.vrm,
                name: "VRM",
            },
            {
                selector: (row: VendorConsignmentModel) => row.vehicle_id,
                name: "Vehicle Instruction ID",
            },
            {
                selector: (row: VendorConsignmentModel) => row.vehicle_job_id,
                name: "Vehicle Job ID",
            },
            {
                selector: (row: VendorConsignmentModel) => row.consignment_id,
                name: "Consignment ID",
            },
            {
                selector: (row: VendorConsignmentModel) => row.agreement_number,
                name: "Agreement #",
            },
            {
                selector: (row: VendorConsignmentModel) => row.service?.title ?? "N/A",
                name: "Service",
            },
            {
                selector: (row: VendorConsignmentModel) => row.supplier_company?.company_name ?? "N/A",
                name: "Supplier",
            },
            {
                selector: (row: VendorConsignmentModel) => row.consignment_status?.title ?? "N/A",
                name: "Status",
            },
            {
                selector: (row: VendorConsignmentModel) => row.date_allocated_string,
                name: "Date Allocated",
            },
            {
                selector: (row: VendorConsignmentModel) => row.date_accepted_string,
                name: "Date Accepted",
            },
            {
                selector: (row: VendorConsignmentModel) => row.date_completed_string,
                name: "Date Completed",
            },
            {
                selector: (row: VendorConsignmentModel) => row.instruction_type?.title ?? "N/A",
                name: "Instruction Type",
            },
        ],
        []
    );

    const getData = useCallback(
        (search: VendorConsignmentSearchModel) => vendorConsignmentService.getConsignments(search),
        []
    );

    return <G3DataTable columns={columns} filters={filters} load={getData} />;
}
