import { VendorAuctionStatsModel } from "@g3r-developers/vamp-types/dist/Vendor/Auction/VendorAuctionStatsModel";
import { VendorVehicleRepairReturnModel } from "@g3r-developers/vamp-types/dist/Vendor/Auction/VendorVehicleRepairReturnModel";
import { VendorVehicleRepairSearchModel } from "@g3r-developers/vamp-types/dist/Vendor/Auction/VendorVehicleRepairSearchModel";
import { DataTableResultModel } from "@g3r-developers/vamp-types/dist/DataTableResultModel";
import { SetReserveModel } from "@g3r-developers/vamp-types/dist/vehicle/reserve/set-reserve-model";
import { GenericVehicleTaskReturnModel } from "@g3r-developers/vamp-types/dist/vehicle/task/generic-vehicle-task-return-model";
import { VehicleTaskSearchModel } from "@g3r-developers/vamp-types/dist/vehicle/task/VehicleTaskSearchModel";
import Api from "../Api";

const BASE_URL = "vendor/auction";

const getAuctionStats = (): Promise<VendorAuctionStatsModel> => {
    return Api.get(`${BASE_URL}/stats`);
};

const getAwaitingReserves = (
    search: VehicleTaskSearchModel
): Promise<DataTableResultModel<GenericVehicleTaskReturnModel>> => {
    return Api.get(`${BASE_URL}/reserve`, search);
};

const getInspections = (search: VehicleTaskSearchModel): Promise<DataTableResultModel<VehicleTaskSearchModel>> => {
    return Api.get(`${BASE_URL}/inspection`, search);
};

const getRepairs = (search: VendorVehicleRepairSearchModel): Promise<VendorVehicleRepairReturnModel> => {
    return Api.get(`${BASE_URL}/repairs`, search);
};

const setReserve = (vehicleId: number, model: SetReserveModel): Promise<boolean> => {
    return Api.post(`${BASE_URL}/reserve/${vehicleId}`, model);
};

const vendorAuctionService = {
    getAuctionStats,
    getAwaitingReserves,
    getInspections,
    getRepairs,
    setReserve,
};

export default vendorAuctionService;
