import { G3Date, G3Dropdown, G3Input } from "@g3r-developers/g3-common";
import { useCallback } from "react";
import { Col, Row } from "reactstrap";
import DropdownService from "Services/DropdownService";

export function EAppraiseFilters({ filters, setFilters }) {
    const handleVehicleJobStatusChange = useCallback(
        (newValue: number[]) => {
            setFilters({ ...filters, vehicle_job_status_ids: newValue });
        },
        [setFilters, filters]
    );

    const handleKeywordChange = useCallback(
        (newValue: string) => {
            setFilters({ ...filters, keyword: newValue });
        },
        [setFilters, filters]
    );

    const handleDateFromChange = useCallback(
        (newValue: string) => {
            setFilters({ ...filters, date_from: newValue });
        },
        [setFilters, filters]
    );

    const handleDateToChange = useCallback(
        (newValue: string) => {
            setFilters({ ...filters, date_to: newValue });
        },
        [setFilters, filters]
    );

    return (
        <>
            <Row>
                <Col>
                    <G3Input label="Keyword" type="text" value={filters.keyword} onChange={handleKeywordChange} />
                </Col>
                <Col>
                    <G3Dropdown
                        loadOptions={(val: string) => DropdownService.attribvals("vehicle_job_status", val)}
                        label="Status"
                        multiple
                        defaultOption={filters.vehicle_job_status_ids ?? []}
                        onChange={handleVehicleJobStatusChange}
                    />
                </Col>
                <Col>
                    <G3Date
                        label="Date Issued From"
                        selectedDate={filters.date_from}
                        onChange={handleDateFromChange}
                        max="today"
                        showTime={false}
                    />
                </Col>
                <Col>
                    <G3Date
                        label="Date Issued To"
                        selectedDate={filters.date_to}
                        onChange={handleDateToChange}
                        max="today"
                        min={filters.date_from}
                    />
                </Col>
            </Row>
        </>
    );
}
