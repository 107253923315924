import NotificationsSideNavLink from "./NotificationsSideNavLink";
import { SideNavLinkPropsExtended } from "./SideNav";

const vendorLinks: SideNavLinkPropsExtended[] = [
    {
        faIconString: "fas fa-home",
        text: "Dashboard",
        to: "/vendor",
    },
    {
        component: (
            <NotificationsSideNavLink
                faIconString={"fas fa-bell"}
                text={"Notifications"}
                to={"/vendor/notifications"}
            />
        ),
        to: "/vendor/notifications",
    },
    {
        faIconString: "fas fa-clipboard-list",
        text: "Instructions",
        to: "/vendor/instructions",
        children: [
            {
                text: "Dashboard",
                to: "/vendor/instructions",
                faIconString: "",
            },
            {
                text: "Map",
                to: "/vendor/instructions/map",
                faIconString: "fa fa-map",
            },
            {
                text: "Services",
                to: "/vendor/instructions/vehicle-jobs",
                faIconString: "",
            },
            {
                text: "Closure Review",
                to: "/vendor/instructions/closure-review",
                faIconString: "",
            },
            {
                text: "Allocations",
                to: "/vendor/instructions/allocations",
                faIconString: "",
            },
        ],
    },
    {
        faIconString: "fas fa-gavel",
        text: "Auctions",
        to: "/vendor/auctions",
    },
    {
        faIconString: "fas fa-users",
        text: "Staff",
        to: "/vendor/staff",
    },
    {
        faIconString: "fas fa-chart-bar",
        text: "KPI",
        to: "/vendor/kpi",
    },
    {
        faIconString: "fas fa-file-import",
        text: "Import Data",
        to: "/vendor/import",
    },
    {
        faIconString: "fas fa-cog",
        text: "Platform Settings",
        to: "/vendor/platform-settings",
    },
];

export default vendorLinks;
