import { G3Date, G3Dropdown, G3Input } from "@g3r-developers/g3-common";
import { VendorVehicleRepairSearchModel } from "@g3r-developers/vamp-types/dist/Vendor/Auction/VendorVehicleRepairSearchModel";
import { Col, Row } from "reactstrap";
import DropdownService from "Services/DropdownService";
import { FiltersProps } from "Types/FiltersProps";

interface RepairsFilterProps extends FiltersProps {
    className?: string;
}

export default function RepairsFilter({ className, filters, setFilters }: RepairsFilterProps) {
    return (
        <Row className={className}>
            <Col>
                <G3Input
                    label="Keyword"
                    type="text"
                    onChange={(e: string) => setFilters((f: VendorVehicleRepairSearchModel) => ({ ...f, keyword: e }))}
                    value={filters.keyword}
                />
            </Col>
            <Col>
                <G3Date
                    label="Next Sale Date"
                    onChange={(e: string) =>
                        setFilters((f: VendorVehicleRepairSearchModel) => ({ ...f, next_sale_date: e }))
                    }
                    selectedDate={filters.sale_date}
                    min="today"
                    showTime={false}
                />
            </Col>
            <Col>
                <G3Dropdown
                    label="Auction Location"
                    defaultOption={filters.auction_location_ids}
                    loadOptions={(val: string) => DropdownService.auctionLocations(val)}
                    onChange={(e: number[]) => {
                        setFilters((f: VendorVehicleRepairSearchModel) => ({ ...f, auction_location_ids: e }));
                    }}
                    multiple
                />
            </Col>
            <Col>
                <G3Dropdown
                    label="Vehicle Task Status"
                    defaultOption={filters.vehicle_task_status_ids}
                    loadOptions={(val: string) => DropdownService.attribvals("task_status", val)}
                    onChange={(e: number[]) => {
                        setFilters((f: VendorVehicleRepairSearchModel) => ({ ...f, vehicle_task_status_ids: e }));
                    }}
                    multiple
                />
            </Col>
        </Row>
    );
}
