import { G3Dropdown, G3Input, IconButton } from "@g3r-developers/g3-common";
import { ReallocateVehicleInstructionModel } from "@g3r-developers/vamp-types/dist/VehicleInstruction/ReallocateVehicleInstructionModel";
import { VehicleJobModel } from "@g3r-developers/vamp-types/dist/VehicleJob/VehicleJobModel";
import useERecoveryToasts from "Hooks/UseERecoveryToasts";
import { useCallback, useState } from "react";
import { Col, Row } from "reactstrap";
import DropdownService from "Services/DropdownService";
import { isEmpty } from "Services/Utils";
import vendorInstructionService from "Services/Vendor/VendorInstructionService";

interface ReallocateServiceFormErrors {
    required_service_id?: string;
}

interface ReallocateServiceFormProps {
    reload: () => void;
    toggle: () => void;
    vehicleJob: VehicleJobModel;
}

export function ReallocateServiceForm({ reload, toggle, vehicleJob }: ReallocateServiceFormProps) {
    const [data, setData] = useState<ReallocateVehicleInstructionModel>({
        reallocated_vehicle_job_id: vehicleJob.vehicle_job_id,
        instruction_phase: vehicleJob.vehicle_instruction?.instruction_phase,
        instruction_type_id: vehicleJob.vehicle_instruction?.instruction_type?.id,
        required_service_id: undefined,
    });
    const [errors, setErrors] = useState<ReallocateServiceFormErrors>({});

    const { successToast, errorToast } = useERecoveryToasts();

    const checkErrors = useCallback((model: ReallocateVehicleInstructionModel) => {
        const e: ReallocateServiceFormErrors = {};

        if (!model.required_service_id) {
            e.required_service_id = "Please select a service";
        }

        setErrors(e);

        return !isEmpty(e);
    }, []);

    const handlePhaseChanged = useCallback((newValue: number) => {
        setData(d => {
            return { ...d, instruction_phase: newValue };
        });
    }, []);

    const handleInstructionTypeChanged = useCallback((newValue: number) => {
        setData(d => {
            return { ...d, instruction_type_id: newValue };
        });
    }, []);

    const handleServiceReqiredChanged = useCallback((newValue: number) => {
        setData(d => {
            return { ...d, required_service_id: newValue };
        });
    }, []);

    const handleNotesChanged = useCallback((newValue: string) => {
        setData(d => {
            return { ...d, notes: newValue };
        });
    }, []);

    const handleReallocate = useCallback(() => {
        const hasErrors = checkErrors(data);

        if (hasErrors) {
            return;
        }

        vendorInstructionService
            .reallocate(vehicleJob.vehicle_instruction.vehicle_id, data)
            .then(() => {
                successToast("Instruction has been reallocated");
                reload();
                toggle();
            })
            .catch(errorToast);
    }, [checkErrors, data, errorToast, reload, successToast, toggle, vehicleJob.vehicle_instruction.vehicle_id]);

    return (
        <Row>
            <Col md="12">
                <G3Dropdown
                    defaultOption={data.instruction_phase}
                    label="Phase"
                    loadOptions={(val: string) => DropdownService.instructionPhases(val)}
                    onChange={handlePhaseChanged}
                />
            </Col>

            <Col md="12">
                <G3Dropdown
                    defaultOption={data.instruction_type_id}
                    label="Amend Instruction Type"
                    loadOptions={(val: string) => DropdownService.attribvals("transport_instruction_type", val)}
                    onChange={handleInstructionTypeChanged}
                />
            </Col>

            <Col md="12">
                <G3Dropdown
                    defaultOption={data.required_service_id}
                    error={errors.required_service_id}
                    label="Service Required"
                    loadOptions={(val: string) => DropdownService.attribvals("e_recovery_service", val)}
                    onChange={handleServiceReqiredChanged}
                />
            </Col>

            <Col md="12">
                <G3Input label="Specific Notes" type="text" value={data.notes} onChange={handleNotesChanged} />
            </Col>

            <Col md="12">
                <IconButton variant="secondary" onClick={handleReallocate} text="Reallocate" className="mt-3" block />
            </Col>
        </Row>
    );
}
