import { G3DataTable, G3Date, G3Dropdown, G3Input, G3Toggle, IconButton } from "@g3r-developers/g3-common";
import { VehicleSearchModel } from "@g3r-developers/vamp-types/dist/vehicle/VehicleSearchModel";
import { VendorVehicleDataTableReturnModel } from "@g3r-developers/vamp-types/dist/Vendor/Vehicle/VendorVehicleDataTableReturnModel";
import useERecoveryToasts from "Hooks/UseERecoveryToasts";
import { useCallback, useMemo, useState } from "react";
import { Col, Row } from "reactstrap";
import DropdownService from "Services/DropdownService";
import vendorVehicleService from "Services/Vendor/VendorVehicleService";

export function VehiclesTab() {
    const [filters, setFilters] = useState<VehicleSearchModel>({} as VehicleSearchModel);

    const { errorToast } = useERecoveryToasts();

    const columns = useMemo(
        () => [
            {
                selector: "vrm",
                name: "VRM",
            },
            {
                name: "Make/Model",
                cell: (row: VendorVehicleDataTableReturnModel) => `${row.make} ${row.model}`,
            },
            {
                name: "Agreement No.",
                selector: "agreement_num",
            },
            {
                name: "Next Sale Date",
                cell: row => (row.next_sale_date_string === "N/A" ? "Awaiting Allocation" : row.next_sale_date_string),
            },
            {
                name: "Auction Location",
                cell: row => row.auction_location ?? "Awaiting Allocation",
            },
            {
                name: "Date on Site",
                cell: row => (row.date_on_site_string === "N/A" ? "Awaiting Delivery" : row.date_on_site_string),
            },
            {
                name: "Sale Status",
                selector: "sold_status",
            },
            {
                name: "Vehicle Status",
                selector: "vehicle_status",
            },
            {
                name: "Grade",
                cell: row => (row.grade === " N/a" ? "Pending" : row.grade),
            },
            {
                name: "Dmg. Estimate",
                cell: row => (row.damage_estimate_string === "N/A" ? "Pending" : row.damage_estimate_string),
            },
            {
                name: "Actions",
                cell: (row: VendorVehicleDataTableReturnModel) => (
                    <IconButton
                        faIconString="fa fa-car"
                        tooltip="View"
                        url={`/vendor/instructions/${row.vehicle_id}`}
                        variant="secondary"
                    />
                ),
            },
        ],
        []
    );

    const loadVehicles = useCallback(
        (paging: VehicleSearchModel) => {
            return vendorVehicleService.searchVehicles(paging).catch(errorToast);
        },
        [errorToast]
    );

    const handleKeywordChange = useCallback(
        (newValue: string) => {
            setFilters((f: VehicleSearchModel) => {
                return { ...f, keyword_search: newValue };
            });
        },
        [setFilters]
    );

    const handleSaleStatusChange = useCallback(
        (newValue: number[]) => {
            setFilters((f: VehicleSearchModel) => {
                return { ...f, sold_status_ids: newValue };
            });
        },
        [setFilters]
    );

    const handleAuctionLocationsChange = useCallback(
        (newValue: number[]) => {
            setFilters((f: VehicleSearchModel) => {
                return { ...f, location_ids: newValue };
            });
        },
        [setFilters]
    );

    const handleSaleDateChange = useCallback(
        (newValue: string) => {
            setFilters((f: VehicleSearchModel) => {
                return { ...f, next_sale_date: newValue };
            });
        },
        [setFilters]
    );
    const handleOnsiteOnlyChange = useCallback(
        (newValue: boolean) => {
            setFilters((f: VehicleSearchModel) => {
                return { ...f, in_stock: newValue };
            });
        },
        [setFilters]
    );

    return (
        <>
            <Row>
                <Col md="2">
                    <G3Input
                        type="text"
                        label="Keyword"
                        onChange={handleKeywordChange}
                        value={filters.keyword_search}
                    />
                </Col>
                <Col md="2">
                    <G3Dropdown
                        label="Sale Status"
                        loadOptions={(val: string) => DropdownService.attribvals("sold_status", val)}
                        onChange={handleSaleStatusChange}
                        multiple
                    />
                </Col>
                <Col md="2">
                    <G3Dropdown
                        label="Auction Location"
                        loadOptions={(val: string) => DropdownService.auctionLocations(val)}
                        onChange={handleAuctionLocationsChange}
                        multiple
                    />
                </Col>
                <Col md="2">
                    <G3Date
                        label="Next Sale Date"
                        onChange={handleSaleDateChange}
                        selectedDate={filters.next_sale_date}
                    />
                </Col>
                <Col md="2">
                    <br />
                    <G3Toggle label="Onsite Only?" onToggle={handleOnsiteOnlyChange} active={filters.in_stock} />
                </Col>
            </Row>
            <br />
            <G3DataTable columns={columns} load={loadVehicles} filters={filters} />
        </>
    );
}
