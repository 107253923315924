import { LoginResponse } from "@g3r-developers/vamp-types/dist/User/LoginResponse";
import SideNavLayout from "Components/Layout/SideNavLayout";
import { useEffect, useState } from "react";
import { Navigate, Outlet, RouteProps } from "react-router-dom";
import { sessionService } from "redux-react-session";

export interface PrivateRouteProps extends RouteProps {
    authenticated: boolean;
    component: any;
    role?: string;
    user?: LoginResponse;
}

export default function PrivateRoute() {
    const [authenticated, setAuthenticated] = useState<boolean>(false);
    const [checked, setChecked] = useState<boolean>(false);

    useEffect(() => {
        sessionService
            .loadSession()
            .then((s: LoginResponse) => {
                setAuthenticated(!(new Date(s.expiry) < new Date()));
                setChecked(true);
            })
            .catch(() => {
                setChecked(true);
                setAuthenticated(false);
            });
    }, []);

    return checked && <>{authenticated ? <SideNavLayout outlet={<Outlet />} /> : <Navigate to={"/login"} />}</>;
}
