import { LoginResponse } from "@g3r-developers/vamp-types/dist/User/LoginResponse";
import "bootstrap/dist/css/bootstrap.min.css";
import { DateTime } from "luxon";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ToastProvider } from "react-toast-notifications";
import { combineReducers, createStore } from "redux";
import { sessionReducer, sessionService, SessionServiceOptions } from "redux-react-session";
import { SessionState } from "redux-react-session/reducer";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

export interface ApplicationState {
    session: SessionState;
}

const reducer = combineReducers({
    session: sessionReducer,
});

const validateSession = (session: LoginResponse) => {
    if (!session || !session.expiry) {
        return false;
    }

    const expiryDate = DateTime.fromISO(session.expiry);

    if (expiryDate < DateTime.now()) {
        return false;
    }

    return true;
};

const store = createStore(reducer);

const options = {
    driver: "INDEXEDDB",
    expires: 360,
    redirectPath: "/",
    refreshOnCheckAuth: true,
    validateSession,
} as SessionServiceOptions;

sessionService.initSessionService(store, options).then(() => {
    ReactDOM.render(
        <Provider store={store}>
            <React.StrictMode>
                <ToastProvider>
                    <App />
                </ToastProvider>
            </React.StrictMode>
        </Provider>,
        document.getElementById("root")
    );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
