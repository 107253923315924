import { UnreadNotificationModel } from "@g3r-developers/vamp-types/dist/Notification/UnreadNotificationModel";
import React, { useCallback, useEffect, useState } from "react";
import notificationService from "Services/NotificationService";
import signalRService, { SignalRConfig } from "Services/SignalRService";
import SideNavLink, { SideNavLinkProps } from "./SideNavLink/SideNavLink";

export default function NotificationsSideNavLink({ children, faIconString, text, to }: SideNavLinkProps) {
    const [notificationCount, setNotificationCount] = useState<number>(0);

    const getConnectionHandlers = useCallback((): Map<string, (msg: any) => void> => {
        const handlers = new Map<string, (msg: any) => void>();

        handlers.set("newNotification", () => setNotificationCount(nc => nc + 1));

        handlers.set("updateUnacknowledgedNotificationsCount", (msg: UnreadNotificationModel) =>
            setNotificationCount(msg.unread)
        );

        return handlers;
    }, []);

    const getUnreadCount = useCallback(() => {
        notificationService.getUnreadCount().then(res => setNotificationCount(res.unread));
    }, []);

    const joinNotificationsHub = useCallback(() => {
        signalRService.getSignalRConnection({
            url: "hubs/notifications",
            joinPath: "Join",
            handlers: getConnectionHandlers(),
            onConnected: () => {},
            onDisconnected: () => {},
        } as SignalRConfig);
    }, [getConnectionHandlers]);

    useEffect(() => {
        joinNotificationsHub();

        getUnreadCount();
    }, [getUnreadCount, joinNotificationsHub]);

    return (
        <SideNavLink
            badge={notificationCount}
            children={children}
            faIconString={faIconString}
            showBadge={true}
            text={text}
            to={to}
        />
    );
}
