import { G3DataTable, IconButton } from "@g3r-developers/g3-common";
import { PagingModel } from "@g3r-developers/vamp-types/dist/paging-model";
import { VehicleDataTableReturnModel } from "@g3r-developers/vamp-types/dist/vehicle/VehicleDataTableReturnModel";
import React from "react";
import { useCallback, useMemo, useState } from "react";
import { Col, Row } from "reactstrap";
import auctionVehicleService from "Services/Auction/AuctionVehicleService";
import LotVehiclesModal from "./LotVehiclesModal";

export default function LottableVehiclesTable() {
    const [filters, setFilters] = useState<PagingModel>({} as PagingModel);
    const [clearSelectedRowsToggle, setClearSelectedRowsToggle] = useState<boolean>(false);
    const [selectedRows, setSelectedRows] = useState<VehicleDataTableReturnModel[]>([]);
    const [showLotModal, setShowLotModal] = useState<boolean>(false);

    const rowsSelected = useCallback((state: { selectedRows: VehicleDataTableReturnModel[] }) => {
        // order ascending
        const localSelectedRows = [...state.selectedRows];
        setSelectedRows(localSelectedRows);
    }, []);

    const clearSelectedRows = useCallback(() => {
        setSelectedRows([]);
        setClearSelectedRowsToggle(p => !p);
    }, []);

    const handleReload = useCallback(() => {
        clearSelectedRows();
        setFilters(p => ({ ...p }));
    }, [clearSelectedRows]);

    const columns = useMemo(
        () => [
            {
                selector: (row: VehicleDataTableReturnModel) => row.vrm,
                name: "Registration",
                sort: "vrm",
                sortable: true,
            },
            {
                selector: (row: VehicleDataTableReturnModel) =>
                    `${row.agreement_number ? row.agreement_number : "N/A"}`,
                name: "Agreement Number",
                sort: "agreement_num",
                sortable: true,
            },
            {
                selector: (row: VehicleDataTableReturnModel) => `${row.make_name} ${row.model_name}`,
                name: "Make/Model",
                sort: "make.make_name",
                sortable: true,
            },
            {
                selector: (row: VehicleDataTableReturnModel) => row.mileage_string,
                name: "Milege",
                sort: "mileage",
                sortable: true,
            },
            {
                selector: (row: VehicleDataTableReturnModel) => row.cap_clean_string,
                name: "CAP Clean",
                sort: "current_valuation.value_trade_clean",
                sortable: true,
            },
            {
                selector: (row: VehicleDataTableReturnModel) => row.runner_status.title,
                name: "Runner Status",
                sort: "runner_status.id",
                sortable: true,
            },
        ],
        []
    );

    const loadData = useCallback((model: PagingModel) => auctionVehicleService.getAuctionableVehicles(model), []);

    const toggleModal = useCallback(() => {
        setShowLotModal(p => !p);
    }, []);

    const contextActions = useMemo(
        () => [
            <React.Fragment key={"payment-received"}>
                <IconButton
                    variant={"secondary"}
                    text={`${selectedRows.length > 1 ? "Lot Vehicles" : "Lot Vehicle"}`}
                    onClick={toggleModal}
                />
            </React.Fragment>,
        ],
        [toggleModal, selectedRows.length]
    );

    return (
        <>
            <Row className={"flex-column"}>
                <Col>
                    <G3DataTable
                        columns={columns}
                        filters={filters}
                        load={loadData}
                        selectable
                        clearSelectedRows={clearSelectedRowsToggle}
                        contextActions={contextActions}
                        rowSelected={rowsSelected}
                    />
                </Col>
            </Row>
            {showLotModal && <LotVehiclesModal vehicles={selectedRows} toggle={toggleModal} reload={handleReload} />}
        </>
    );
}
