import { G3DataTable, IconButton } from "@g3r-developers/g3-common";
import { GenericVehicleTaskReturnModel } from "@g3r-developers/vamp-types/dist/vehicle/task/generic-vehicle-task-return-model";
import { VehicleTaskSearchModel } from "@g3r-developers/vamp-types/dist/vehicle/task/VehicleTaskSearchModel";
import useERecoveryToasts from "Hooks/UseERecoveryToasts";
import { useCallback, useMemo } from "react";
import vendorAuctionService from "Services/Vendor/VendorAuctionService";
import { FiltersProps } from "Types/FiltersProps";
import { CapTableCell } from "../../../Common/CapTableCell";
import ReservesInputCell from "./ReservesInputCell";

export interface AwaitingReservesTableProps extends FiltersProps {
    reloadStats?(): void;
}

export default function AwaitingReservesTable({ filters, setFilters, reloadStats }: AwaitingReservesTableProps) {
    const reloadTable = useCallback(() => {
        setFilters((f: VehicleTaskSearchModel) => {
            return {
                ...f,
                reload: true,
            };
        });
    }, [setFilters]);

    const { errorToast } = useERecoveryToasts();

    const columns = useMemo(
        () => [
            {
                name: "Agreement No.",
                selector: "instruction_agreement_num",
            },
            {
                selector: "vrm",
                name: "VRM",
            },
            {
                cell: row => row.vin ?? "-",
                name: "VIN",
                grow: 2,
            },
            {
                name: "Make/Model",
                cell: (row: GenericVehicleTaskReturnModel) => `${row.make_name} ${row.model_name}`,
                grow: 2,
            },
            {
                name: "Grade",
                cell: row => (row.vehicle_grade.id === 0 ? "Pending" : row.vehicle_grade.title),
            },
            {
                name: "Dmg. Estimate",
                cell: row => (row.vehicle_grade.id === 0 ? "Pending" : row.vehicle_grade.title),
            },
            {
                name: "Inspection Report",
                cell: (row: GenericVehicleTaskReturnModel) => (
                    <>
                        {row.vendor_inspection_media ? (
                            <IconButton
                                externalUrl={row.vendor_inspection_media.url}
                                targetString="_blank"
                                variant="secondary"
                                faIconString="fas fa-file"
                            />
                        ) : (
                            <IconButton disabled variant="secondary" faIconString="fas fa-file" />
                        )}
                    </>
                ),
                center: true,
            },
            {
                name: "Auction Location",
                selector: "location_name",
                cell: row => row.location_name ?? "Awaiting Allocation",
            },
            {
                name: "Next Sale Date",
                cell: row => (row.next_sale_string === "N/A" ? "Awaiting Allocation" : row.next_sale_string),
            },
            {
                name: "CAP Data",
                cell: (row: GenericVehicleTaskReturnModel) => <CapTableCell caps={row.caps} />,
                grow: 1.5,
                center: true,
            },
            {
                name: "Auction Valuation",
                selector: (row: GenericVehicleTaskReturnModel) => (
                    <>{row.g3_valuation === 0 ? "Pending" : row.g3_valuation_string}</>
                ),
            },
            {
                name: "Set Reserve",
                cell: (row: GenericVehicleTaskReturnModel) => (
                    <ReservesInputCell row={row} reload={reloadTable} reloadStats={reloadStats} />
                ),
                grow: 2,
            },
        ],
        [reloadStats, reloadTable]
    );

    const loadTableData = useCallback(
        (search: VehicleTaskSearchModel) => {
            return vendorAuctionService.getAwaitingReserves(search).catch(errorToast);
        },
        [errorToast]
    );

    return (
        <>
            <G3DataTable columns={columns} load={loadTableData} filters={filters} />
        </>
    );
}
