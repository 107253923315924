import { G3DataTable, IconButton } from "@g3r-developers/g3-common";
import { VendorVehicleRepairReturnModel } from "@g3r-developers/vamp-types/dist/Vendor/Auction/VendorVehicleRepairReturnModel";
import { VendorVehicleRepairSearchModel } from "@g3r-developers/vamp-types/dist/Vendor/Auction/VendorVehicleRepairSearchModel";
import useERecoveryToasts from "Hooks/UseERecoveryToasts";
import { useCallback, useMemo } from "react";
import vendorAuctionService from "Services/Vendor/VendorAuctionService";
import { FiltersProps } from "Types/FiltersProps";
import RepairsActionCell from "./RepairsActionCell";

export interface RepairsTableProps extends FiltersProps {
    reloadStats?(): void;
}

export default function RepairsTable({ filters, setFilters, reloadStats }: RepairsTableProps) {
    const reloadTable = useCallback(() => {
        setFilters((f: VendorVehicleRepairSearchModel) => ({
            ...f,
            reload: true,
        }));
    }, [setFilters]);

    const { errorToast } = useERecoveryToasts();

    const columns = useMemo(
        () => [
            {
                selector: "vehicle_id",
                name: "Vehicle ID",
            },
            {
                selector: "vrm",
                name: "VRM",
            },
            {
                name: "VIN",
                cell: (row: VendorVehicleRepairReturnModel) => row.vin ?? "-",
                grow: 2,
            },
            {
                name: "Make/Model",
                cell: (row: VendorVehicleRepairReturnModel) => `${row.make} ${row.model}`,
                grow: 2,
            },
            {
                name: "Agreement No.",
                selector: "agreement_num",
            },
            {
                name: "Current Grade",
                cell: (row: VendorVehicleRepairReturnModel) =>
                    !row.current_grade || row.current_grade === "N/A" ? "Pending" : row.current_grade,
            },
            {
                name: "Potential Grade",
                cell: (row: VendorVehicleRepairReturnModel) =>
                    !row.potential_grade || row.potential_grade === "N/A" ? "Pending" : row.potential_grade,
            },
            {
                name: "Refurb Quote",
                cell: (row: VendorVehicleRepairReturnModel) => (row.refurb_quote ? `£${row.refurb_quote}` : "TBC"),
            },
            {
                name: "Inspection Report",
                cell: (row: VendorVehicleRepairReturnModel) => (
                    <>
                        {row.inspection_report_url ? (
                            <IconButton
                                externalUrl={row.inspection_report_url}
                                targetString="_blank"
                                variant="secondary"
                                faIconString="fas fa-file"
                            />
                        ) : (
                            <IconButton disabled variant="secondary" faIconString="fas fa-file" />
                        )}
                    </>
                ),
                center: true,
            },
            {
                name: "Auction Location",
                cell: (row: VendorVehicleRepairReturnModel) => row.auction_location ?? "Awaiting Allocation",
            },
            {
                name: "Next Sale Date",
                cell: (row: VendorVehicleRepairReturnModel) =>
                    !row.next_sale_date_string || row.next_sale_date_string === "N/A"
                        ? "Awaiting Allocation"
                        : row.next_sale_date_string,
            },
            {
                name: "Actions",
                cell: (row: VendorVehicleRepairReturnModel) => (
                    <RepairsActionCell row={row} reload={reloadTable} reloadStats={reloadStats} />
                ),
            },
        ],
        [reloadStats, reloadTable]
    );

    const loadRepairs = useCallback(
        (paging: VendorVehicleRepairSearchModel) => {
            return vendorAuctionService.getRepairs(paging).catch(errorToast);
        },
        [errorToast]
    );

    return <G3DataTable columns={columns} load={loadRepairs} filters={filters} />;
}
