import { G3Input, G3Modal } from "@g3r-developers/g3-common";
import { RejectProvisionalModel } from "@g3r-developers/vamp-types/dist/bid/RejectProvisionalModel";
import { ProvisionalSearchReturnModel } from "@g3r-developers/vamp-types/dist/sale/ProvisionalSearchReturnModel";
import useERecoveryToasts from "Hooks/UseERecoveryToasts";
import { useCallback, useState } from "react";
import { isEmpty } from "Services/Utils";
import provisionalService from "Services/Vendor/ProvisionalService";

interface RejectProvisionalBidErrors {
    contactId?: string;
    offer?: string;
}

interface RejectProvisionalBidModalProps {
    row: ProvisionalSearchReturnModel;
    close(): void;
    reload(): void;
    reloadStats?(): void;
}

export default function RejectProvisionalBidModal({ close, reload, reloadStats, row }: RejectProvisionalBidModalProps) {
    const [errors, setErrors] = useState<RejectProvisionalBidErrors>({});
    const [data, setData] = useState<RejectProvisionalModel>({
        contact_id: 0,
        required_amount: row.bid_amount,
    });
    const { successToast, errorToast } = useERecoveryToasts();

    const checkErrors = useCallback(
        (model: RejectProvisionalModel) => {
            const e: RejectProvisionalBidErrors = {};

            if (!model.required_amount) {
                e.offer = "Please enter a Must Be Figure";
            }

            if (model.required_amount <= row.bid_amount) {
                e.offer = `The Must Be Figure should be greater than the current bid amount (${row.bid_amount_string})`;
            }

            setErrors(e);

            return !isEmpty(e);
        },
        [row.bid_amount, row.bid_amount_string]
    );

    const handleOfferChange = useCallback((value: number) => setData(d => ({ ...d, required_amount: value })), []);

    const handleReject = useCallback(() => {
        const hasErrors = checkErrors(data);

        if (hasErrors) {
            return;
        }

        provisionalService
            .reject(row.bid_id, data)
            .then(() => {
                successToast("Your response was saved successfully");
                reload();
                reloadStats && reloadStats();
                close();
            })
            .catch(errorToast);
    }, [row.bid_id, checkErrors, close, data, errorToast, reload, reloadStats, successToast]);

    return (
        <G3Modal save={handleReject} title="Must Be Figure" toggle={close}>
            <h5>Current bid amount: {row.bid_amount_string}</h5>
            <G3Input
                error={errors.offer}
                label="What is the Must Be Figure?"
                onChange={handleOfferChange}
                type="number"
                value={data.required_amount}
            />
        </G3Modal>
    );
}
