import { G3Date, G3Dropdown, G3Input } from "@g3r-developers/g3-common";
import { VendorConsignmentSearchModel } from "@g3r-developers/vamp-types/dist/Consignment/VendorConsignmentSearchModel";
import React from "react";
import { Col, Row } from "reactstrap";
import DropdownService from "Services/DropdownService";

interface VendorConsignmentFiltersProps {
    className?: string;
    filters: VendorConsignmentSearchModel;
    setFilters: React.Dispatch<React.SetStateAction<VendorConsignmentSearchModel>>;
}

export default function VendorConsignmentFilters({ className, filters, setFilters }: VendorConsignmentFiltersProps) {
    return (
        <Row className={className}>
            <Col>
                <G3Input
                    label={"Keyword"}
                    onChange={(val: string) => setFilters(f => ({ ...f, keyword: val }))}
                    value={filters.keyword}
                />
            </Col>

            <Col>
                <G3Dropdown
                    defaultOption={filters.consignment_status_ids}
                    label={"Consignment Status"}
                    loadOptions={(search: string) => DropdownService.attribvals("consignment_status", search)}
                    multiple
                    onChange={(val: number[]) => setFilters(f => ({ ...f, consignment_status_ids: val }))}
                />
            </Col>

            <Col>
                <G3Date
                    label={"Date Allocated From"}
                    onChange={(val: string) => setFilters(f => ({ ...f, date_allocated_from: val }))}
                    selectedDate={filters.date_allocated_from}
                />
            </Col>

            <Col>
                <G3Date
                    label={"Date Allocated To"}
                    onChange={(val: string) => setFilters(f => ({ ...f, date_allocated_to: val }))}
                    selectedDate={filters.date_allocated_to}
                />
            </Col>

            <Col>
                <G3Dropdown
                    defaultOption={filters.instruction_type_ids}
                    label={"Instruction Type"}
                    loadOptions={(search: string) => DropdownService.attribvals("transport_instruction_type", search)}
                    multiple
                    onChange={(val: number[]) => setFilters(f => ({ ...f, instruction_type_ids: val }))}
                />
            </Col>

            <Col>
                <G3Dropdown
                    defaultOption={filters.service_ids}
                    label={"Service"}
                    loadOptions={(search: string) => DropdownService.attribvals("e_recovery_service", search)}
                    multiple
                    onChange={(val: number[]) => setFilters(f => ({ ...f, service_ids: val }))}
                />
            </Col>
        </Row>
    );
}
