import { LoginResponse } from "@g3r-developers/vamp-types/dist/User/LoginResponse";
import { useEffect, useState } from "react";
import authService from "Services/AuthService";

export default function useSession() {
    const [session, setSession] = useState<LoginResponse>(undefined);

    useEffect(() => {
        authService.getUser().then(setSession);
    }, []);

    return {
        session,
    };
}
