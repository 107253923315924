import { G3Date, G3Dropdown, G3Input } from "@g3r-developers/g3-common";
import { VehicleTaskSearchModel } from "@g3r-developers/vamp-types/dist/vehicle/task/VehicleTaskSearchModel";
import { Col, Row } from "reactstrap";
import DropdownService from "Services/DropdownService";
import { FiltersProps } from "Types/FiltersProps";

interface ProvisionalBidsFilterProps extends FiltersProps {
    className?: string;
}

export default function ProvisionalBidsFilter({ className, filters, setFilters }: ProvisionalBidsFilterProps) {
    return (
        <Row className={className}>
            <Col>
                <G3Input
                    label={"Keyword"}
                    value={filters.keyword}
                    onChange={(e: string) =>
                        setFilters((f: VehicleTaskSearchModel) => {
                            return { ...f, keyword: e };
                        })
                    }
                />
            </Col>
            <Col>
                <G3Date
                    label="Next Sale Date"
                    onChange={(e: string) =>
                        setFilters((f: VehicleTaskSearchModel) => {
                            return { ...f, sale_date: e };
                        })
                    }
                    selectedDate={filters.sale_date}
                    min="today"
                    showTime={false}
                />
            </Col>
            <Col>
                <G3Dropdown
                    label={"Auction Location"}
                    loadOptions={(search: string) => DropdownService.auctionLocations(search)}
                    multiple
                    onChange={(e: number[]) =>
                        setFilters((f: VehicleTaskSearchModel) => {
                            return { ...f, auction_location_id: e };
                        })
                    }
                />
            </Col>
        </Row>
    );
}
