import { lazy } from "react";
import { Route, Routes as Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import SupplierRoutes from "./SupplierRoutes";
import VendorRoute from "./VendorRoute";
import AuctionRoutes from "./AuctionRoutes";

const LoginPage = lazy(() => import("Pages/LoginPage"));
const DashboardPage = lazy(() => import("Pages/DashboardPage"));
const UserVerifyPage = lazy(() => import("Pages/UserVerifyPage"));

export default function Routes() {
    return (
        <Switch>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/user/verify/:userId" element={<UserVerifyPage />} />
            <Route path="/" element={<PrivateRoute />}>
                <Route index element={<DashboardPage />} />
                <Route path="/dashboard" element={<DashboardPage />} />
                <Route path="/vendor/*" element={<VendorRoute />} />
                <Route path="/supplier/*" element={<SupplierRoutes />} />
                <Route path="/auction/*" element={<AuctionRoutes />} />
            </Route>
        </Switch>
    );
}
