import VendorAuctionPage from "Pages/Vendor/Auction/VendorAuctionPage";
import EAppraisePage from "Pages/Vendor/EAppraise/EAppraisePage";
import { default as VendorClosureReviewPage } from "Pages/Vendor/Instruction/Closure/VendorClosureReviewPage";
import VendorVehicleJobsPage from "Pages/Vendor/Instruction/VendorVehicleJobsPage";
import VendorConsignmentsPage from "Pages/Vendor/VendorConsignmentsPage";
import { lazy } from "react";
import { Route, Routes } from "react-router-dom";

export default function VendorRoute() {
    const AllocationsPage = lazy(() => import("Pages/Vendor/Instruction/AllocationsPage"));
    const FeesAwaitingAcceptancePage = lazy(() => import("Pages/Vendor/Instruction/Fees/FeesAwaitingAcceptancePage"));
    const NotificationsPage = lazy(() => import("Pages/NotificationsPage"));
    const PreArrangementPage = lazy(() => import("Pages/Vendor/Instruction/PreArrangementPage"));
    const VendorHomePage = lazy(() => import("Pages/Vendor/VendorHomePage"));
    const VendorImportDataPage = lazy(() => import("Pages/Vendor/VendorImportDataPage"));
    const VendorImportVehicleViewPage = lazy(() => import("Pages/Vendor/VendorImportVehicleViewPage"));
    const VendorInstructionMapPage = lazy(() => import("Pages/Vendor/VendorInstructionMapPage"));
    const VendorInstructionsPage = lazy(() => import("Pages/Vendor/VendorInstructionsPage"));
    const VendorKpiPage = lazy(() => import("Pages/Vendor/VendorKpiPage"));
    const VendorOutstandingRecoveriesPage = lazy(() => import("Pages/Vendor/VendorOutstandingRecoveriesPage"));
    const VendorPlatformSettingsPage = lazy(() => import("Pages/Vendor/VendorPlatformSettingsPage"));
    const VendorStaffPage = lazy(() => import("Pages/Vendor/VendorStaffPage"));
    const VendorVehiclesPage = lazy(() => import("Pages/Vendor/VendorVehiclesPage"));
    const ViewConsignmnentsExtenstionRequestsPage = lazy(
        () => import("Pages/Vendor/VendorConsignmentExtensionRequestsPage")
    );
    const VendorAwaitingAcceptancePage = lazy(() => import("Pages/Vendor/VendorAwaitingAcceptancePage"));
    const ViewInstructionPage = lazy(() => import("Pages/Vendor/Instruction/ViewInstructionPage"));

    return (
        <Routes>
            <Route element={<VendorHomePage />} index />
            <Route element={<VendorVehiclesPage />} path="/vehicles" />
            <Route element={<VendorInstructionsPage />} path="/instructions" />
            <Route element={<AllocationsPage />} path={"/instructions/allocations"} />
            <Route element={<VendorClosureReviewPage />} path="/instructions/closure-review" />
            <Route element={<FeesAwaitingAcceptancePage />} path={"/instructions/fees/awaiting-approval"} />
            <Route element={<VendorInstructionMapPage />} path="/instructions/map" />
            <Route element={<PreArrangementPage />} path="/instructions/pre-arrangements/:transportId" />
            <Route element={<VendorVehicleJobsPage />} path="/instructions/vehicle-jobs" />
            <Route element={<ViewInstructionPage />} path="/instructions/:vehicleId" />
            <Route element={<ViewConsignmnentsExtenstionRequestsPage />} path="/consignment-extensions" />
            <Route element={<VendorImportDataPage />} path="/import" />
            <Route element={<VendorImportVehicleViewPage />} path="/import/:importId" />
            <Route element={<VendorConsignmentsPage />} path="/consignments" />
            <Route element={<VendorAwaitingAcceptancePage />} path="/awaiting-acceptance" />
            <Route element={<VendorStaffPage />} path="/staff" />
            <Route element={<VendorOutstandingRecoveriesPage />} path="/outstanding" />
            <Route element={<EAppraisePage />} path="/e-appraise" />
            <Route element={<VendorKpiPage />} path="/kpi" />
            <Route element={<VendorAuctionPage />} path="/auctions" />

            <Route element={<NotificationsPage />} path={"/notifications"} />
            <Route element={<VendorPlatformSettingsPage />} path={"/platform-settings"} />
        </Routes>
    );
}
