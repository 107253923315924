import React, { useCallback } from "react";
import { NavLink } from "react-router-dom";
import { Badge, Col, Row, UncontrolledCollapse } from "reactstrap";
import "./SideNavLink.scss";

export interface SideNavLinkProps {
    badge?: React.ReactNode;
    children?: SideNavLinkProps[];
    component?: React.ReactNode;
    faIconString?: string;
    showBadge?: boolean;
    text?: string;
    to: string;
}

export default function SideNavLink({ badge, children, faIconString, showBadge, text, to }: SideNavLinkProps) {
    const idName = useCallback(str => {
        return str.replaceAll(" ", "-").toLowerCase();
    }, []);

    return (
        <>
            <NavLink className={"sidebar-link"} id={children ? `nav-toggle-${idName(text)}` : undefined} to={to}>
                <Row className={"align-items-center flex-nowrap w-100"}>
                    <Col className={"sidebar-link-content"} xs={showBadge ? 10 : 12}>
                        <Row className={"align-items-center flex-nowrap"}>
                            <Col xs={1}>
                                <i className={faIconString} />
                            </Col>

                            <Col className={"ps-0 text-start"} xs={11}>
                                <span className={"ms-3"}>{text}</span>
                            </Col>
                        </Row>
                    </Col>

                    {showBadge && (
                        <Col className={"justify-content-end ps-0"} xs={2}>
                            <Badge className={"p-1 w-100"} color={"danger"} pill>
                                {badge}
                            </Badge>
                        </Col>
                    )}
                </Row>
            </NavLink>

            {children && (
                <UncontrolledCollapse className={"first-level"} toggler={`nav-toggle-${idName(text)}`}>
                    {children.map(c => (
                        <NavLink className={"sidebar-link"} end key={c.to} to={c.to}>
                            {c.text}
                        </NavLink>
                    ))}
                </UncontrolledCollapse>
            )}
        </>
    );
}
