import { IconButton } from "@g3r-developers/g3-common";
import { useCallback, useState } from "react";
import { VendorClosureModal } from "./VendorClosureModal";

interface VendorClosureActionCellProps {
    reload: () => void;
    vehicleJobId: number;
}

export function VendorClosureActionCell({ reload, vehicleJobId }: VendorClosureActionCellProps) {
    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleModal = useCallback(() => {
        setShowModal(!showModal);
    }, [showModal]);

    return (
        <>
            <IconButton faIconString="fa fa-eye" variant="secondary" tooltip="View Closure" onClick={toggleModal} />
            {showModal && <VendorClosureModal reload={reload} toggle={toggleModal} vehicleJobId={vehicleJobId} />}
        </>
    );
}
