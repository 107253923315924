import { VehicleTaskSearchModel } from "@g3r-developers/vamp-types/dist/vehicle/task/VehicleTaskSearchModel";
import ProvisionalBidsFilter from "Components/Vendor/Auction/ProvisionalBids/ProvisionalBidsFilter";
import ProvisionalBidsTable from "Components/Vendor/Auction/ProvisionalBids/ProvisionalBidsTable";
import { useState } from "react";

interface ProvisionalsTabProps {
    reloadStats?(): void;
}

export function ProvisionalsTab({ reloadStats }: ProvisionalsTabProps) {
    const [filters, setFilters] = useState<VehicleTaskSearchModel>({} as VehicleTaskSearchModel);

    return (
        <>
            <ProvisionalBidsFilter className="mt-2 mb-3" filters={filters} setFilters={setFilters} />
            <ProvisionalBidsTable filters={filters} setFilters={setFilters} reloadStats={reloadStats} />
        </>
    );
}
