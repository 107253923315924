export enum InstructionHostileAgentClosureReason {
    VehicleCollected = 3747,
    NewKeeperInnocentPurchaser = 3748,
    MoneyCollectedPartArrears = 3749,
    MoneyCollectedFullArrears = 3750,
    TraceCompleted = 3751,
    DriveByCompleted = 3752,
    ReportedStolen = 3753,
    OutOfArea = 3754,
    UnableToReportStolen = 3755,
    UnableToLocateVehicle = 3756,
    UnableToLocateCustomer = 3757,
    ClosedOnClientRequest = 3758,
    ReturnOfGoodsRequired = 3759,
    ScrappedWrittenOff = 3760,
    OtherReason = 3761,
    CompletedSuccessfully = 3805,
}
