import { DataTableResultModel } from "@g3r-developers/vamp-types/dist/DataTableResultModel";
import { AllocatePreRecoverySearchModel } from "@g3r-developers/vamp-types/dist/PreRecoverySearch/AllocatePreRecoverySearchModel";
import { PreRecoverySearchFeeCalculationModel } from "@g3r-developers/vamp-types/dist/PreRecoverySearch/PreRecoverySearchFeeCalculationModel";
import { PreRecoverySearchFeeModel } from "@g3r-developers/vamp-types/dist/PreRecoverySearch/PreRecoverySearchFeeModel";
import { PreRecoverySearchModel } from "@g3r-developers/vamp-types/dist/PreRecoverySearch/PreRecoverySearchModel";
import { PreRecoverySearchSearchModel } from "@g3r-developers/vamp-types/dist/PreRecoverySearch/PreRecoverySearchSearchModel";
import * as Api from "../Api";

const BaseUrl = "Vendor/Pre-Recovery-Search";

const allocate = async (model: AllocatePreRecoverySearchModel): Promise<void> => {
    return Api.post<void>(`${BaseUrl}/allocate`, model);
};

const allocateMultiple = async (model: AllocatePreRecoverySearchModel): Promise<void> => {
    return Api.post<void>(`${BaseUrl}/Allocate`, model);
};

const calculateFee = async (model: PreRecoverySearchFeeCalculationModel): Promise<PreRecoverySearchFeeModel> => {
    return Api.post<PreRecoverySearchFeeModel>(`${BaseUrl}/fee/calculate`, model);
};

export const getTraceReport = (preRecoverySearchId: number) => {
    return Api.get(`${BaseUrl}/${preRecoverySearchId}/Trace-Report`);
};

const searchToAllocate = async (
    model: PreRecoverySearchSearchModel
): Promise<DataTableResultModel<PreRecoverySearchModel>> => {
    return Api.get<DataTableResultModel<PreRecoverySearchModel>>(`${BaseUrl}/to-allocate`, model);
};

const vendorPreRecoverySearchService = {
    allocate,
    allocateMultiple,
    calculateFee,
    getTraceReport,
    searchToAllocate,
};

export default vendorPreRecoverySearchService;
