import { G3DataTable, IconButton } from "@g3r-developers/g3-common";
import { ProvisionalSearchReturnModel } from "@g3r-developers/vamp-types/dist/sale/ProvisionalSearchReturnModel";
import { VehicleTaskSearchModel } from "@g3r-developers/vamp-types/dist/vehicle/task/VehicleTaskSearchModel";
import { useCallback, useMemo } from "react";
import provisionalService from "Services/Vendor/ProvisionalService";
import { FiltersProps } from "Types/FiltersProps";
import ProvisionalBidsActionCell from "./ProvisionalBidsActionCell";

export interface ProvisionalBidsTableProps extends FiltersProps {
    reloadStats?(): void;
}

export default function ProvisionalBidsTable({ filters, setFilters, reloadStats }: ProvisionalBidsTableProps) {
    const reloadTable = useCallback(() => {
        setFilters((f: VehicleTaskSearchModel) => {
            return {
                ...f,
                reload: true,
            };
        });
    }, [setFilters]);

    const columns = useMemo(
        () => [
            {
                name: "Agreement No.",
                selector: "instruction_agreement_num",
            },
            {
                selector: "vrm",
                name: "VRM",
            },
            {
                selector: "vin",
                name: "VIN",
                grow: 2,
            },
            {
                name: "Make/Model",
                cell: (row: ProvisionalSearchReturnModel) => `${row.make_name} ${row.model_name}`,
                grow: 2,
            },
            {
                name: "Grade",
                selector: "vehicle_grade.title",
            },
            {
                name: "Dmg. Estimate",
                selector: "damage_estimate_string",
            },
            {
                name: "Inspection Report",
                cell: (row: ProvisionalSearchReturnModel) => (
                    <>
                        {row.vendor_inspection_media && (
                            <IconButton
                                externalUrl={row.vendor_inspection_media.url}
                                size="sm"
                                targetString="_blank"
                                text="View PDF"
                                variant="secondary"
                                faIconString="fas fa-file me-1"
                            />
                        )}
                    </>
                ),
                grow: 2,
            },
            {
                name: "Auction Location",
                selector: "location_name",
            },
            {
                name: "Next Sale Date",
                selector: "next_sale_string",
            },
            {
                name: "CAP Data",
                selector: (row: ProvisionalSearchReturnModel) => (
                    <>
                        Clean {row.caps?.cap_clean_string}
                        <br />
                        Avg {row.caps?.cap_avg_string}
                        <br />
                        Below {row.caps?.cap_low_string}
                    </>
                ),
                grow: 2,
            },
            {
                name: "Reserve",
                selector: "reserve_price_string",
            },
            {
                name: "Provisional Bid",
                selector: "bid_amount_string",
            },
            {
                name: "Actions",
                cell: (row: ProvisionalSearchReturnModel) => (
                    <ProvisionalBidsActionCell row={row} reload={reloadTable} reloadStats={reloadStats} />
                ),
                grow: 2,
            },
        ],
        [reloadStats, reloadTable]
    );

    const loadTableData = useCallback((search: VehicleTaskSearchModel) => {
        return provisionalService.getProvisionalBids(search);
    }, []);

    return <G3DataTable columns={columns} load={loadTableData} filters={filters} />;
}
