import { CloseConsignmentModel } from "@g3r-developers/vamp-types/dist/Consignment/CloseConsignmentModel";
import { SimpleConsignmentModel } from "@g3r-developers/vamp-types/dist/Consignment/SimpleConsignmentModel";
import { VendorConsignmentModel } from "@g3r-developers/vamp-types/dist/Consignment/VendorConsignmentModel";
import { VendorConsignmentSearchModel } from "@g3r-developers/vamp-types/dist/Consignment/VendorConsignmentSearchModel";
import { DataTableResultModel } from "@g3r-developers/vamp-types/dist/DataTableResultModel";
import Api from "../Api";

const BaseUrl = "vendor/consignment";

const closeConsignment = (consignmentId: number, model: CloseConsignmentModel): Promise<SimpleConsignmentModel> => {
    return Api.post<SimpleConsignmentModel>(`${BaseUrl}/${consignmentId}/close`, model);
};

const getConsignments = async (
    model: VendorConsignmentSearchModel
): Promise<DataTableResultModel<VendorConsignmentModel>> => {
    return Api.get<DataTableResultModel<VendorConsignmentModel>>(`${BaseUrl}`, model);
};

const vendorConsignmentService = {
    closeConsignment,
    getConsignments,
};

export default vendorConsignmentService;
