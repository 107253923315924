import { AuctionVehicleModel } from "@g3r-developers/vamp-types/dist/Auction/AuctionVehicleModel";
import { AuctionVehicleSearchModel } from "@g3r-developers/vamp-types/dist/Auction/AuctionVehicleSearchModel";
import { AuctionUpdateVehicleModel } from "@g3r-developers/vamp-types/dist/Auction/Vehicle/AuctionUpdateVehicleModel";
import { AuctionUploadInspectionModel } from "@g3r-developers/vamp-types/dist/Auction/Vehicle/AuctionUploadInspectionModel";
import { AuctionSiteVehicleModel } from "@g3r-developers/vamp-types/dist/Auction/AuctionSiteVehicleModel";
import { DataTableResult } from "@g3r-developers/vamp-types/dist/data-table-result";
import { VehicleDataTableReturnModel } from "@g3r-developers/vamp-types/dist/vehicle/VehicleDataTableReturnModel";
import { MediaModel } from "@g3r-developers/vamp-types/dist/media/media-model";
import { UploadModel } from "@g3r-developers/vamp-types/dist/media/upload-model";
import { PagingModel } from "@g3r-developers/vamp-types/dist/paging-model";
import { VehicleModel } from "@g3r-developers/vamp-types/dist/vehicle/VehicleModel";
import Api from "../Api";

const BaseUrl = "auction/vehicle";

const getVehicleById = async (vehicleId: number): Promise<VehicleModel> => {
    return Api.get<VehicleModel>(`${BaseUrl}/${vehicleId}`);
};

const updateVehicle = (vehicleId: number, data: AuctionUpdateVehicleModel): Promise<AuctionUpdateVehicleModel> => {
    return Api.put(`${BaseUrl}/${vehicleId}`, data);
};

const uploadInspection = (vehicleId: number, file: File, data: AuctionUploadInspectionModel): Promise<void> => {
    return Api.postFile(`${BaseUrl}/${vehicleId}/Inspection`, file, data);
};

const uploadVehicleImage = (vehicleId: number, file: File, data: UploadModel): Promise<MediaModel> => {
    return Api.postFile(`${BaseUrl}/${vehicleId}/Image`, file, data);
};

const vehicles = async (model: AuctionVehicleSearchModel): Promise<DataTableResult<AuctionVehicleModel>> => {
    return Api.get<DataTableResult<AuctionVehicleModel>>(`${BaseUrl}`, model);
};

const getAuctionableVehicles = async (model: PagingModel): Promise<DataTableResult<VehicleDataTableReturnModel>> => {
    return Api.get<DataTableResult<VehicleDataTableReturnModel>>(`${BaseUrl}/auctionable/vehicles`, model);
};

const siteVehicle = async (vehicleId: number, model: AuctionSiteVehicleModel) => {
    return Api.patch(`${BaseUrl}/${vehicleId}/Site`, model);
};

const auctionVehicleService = {
    getAuctionableVehicles,
    getVehicleById,
    siteVehicle,
    updateVehicle,
    uploadInspection,
    uploadVehicleImage,
    vehicles,
};

export default auctionVehicleService;
