import SideNav from "Components/Nav/SideNav/SideNav";
import React from "react";

interface SideNavLayoutProps {
    outlet: React.ReactElement | null;
}

export default function SideNavLayout({ outlet }: SideNavLayoutProps) {
    return (
        <>
            <SideNav />
            <div className="page-wrapper p-3">{outlet}</div>
        </>
    );
}
