import { LoginResponse } from "@g3r-developers/vamp-types/dist/User/LoginResponse";
import * as signalR from "@microsoft/signalr";
import authService from "./AuthService";

export interface SignalRConfig {
    url: string;
    joinPath: string;
    joinData?: any;
    handlers: Map<string, (msg: any) => void>;
    onDisconnected(): void;
    onConnected(): void;
}

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

async function startConnection(connection: signalR.HubConnection, config: SignalRConfig) {
    return connection
        .start()
        .then(() => {
            config.onConnected();

            if (!config.joinData) {
                connection.invoke(config.joinPath).catch(console.error);
            } else {
                connection.invoke(config.joinPath, config.joinData).catch(console.error);
            }
        })
        .then(() => {
            setupCallbacks(connection, config);
        })
        .catch(async () => {
            config.onDisconnected();

            return delay(500).then(() => {
                return startConnection(connection, config);
            });
        });
}

const setupCallbacks = (connection: signalR.HubConnection, config: SignalRConfig) => {
    config.handlers.forEach((handler, key) => {
        connection.on(key, handler);
    });

    connection.onclose(async () => {
        config.onDisconnected();
        return delay(500).then(() => startConnection(connection, config));
    });

    return connection;
};

export const getSignalRConnection = async (config: SignalRConfig): Promise<signalR.HubConnection> => {
    return authService
        .getUser()
        .then((user: LoginResponse) => {
            return new signalR.HubConnectionBuilder()
                .withUrl(process.env.REACT_APP_SIGNALR_URL + config.url, {
                    accessTokenFactory: () => user.token,
                })
                .build();
        })
        .then((connection: signalR.HubConnection) => startConnection(connection, config))
        .catch();
};

const signalRService = {
    getSignalRConnection,
};

export default signalRService;
