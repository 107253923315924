import { VehicleTaskSearchModel } from "@g3r-developers/vamp-types/dist/vehicle/task/VehicleTaskSearchModel";
import AwaitingReservesFilter from "Components/Vendor/Auction/Reserves/AwaitingReservesFilter";
import AwaitingReservesTable from "Components/Vendor/Auction/Reserves/AwaitingReservesTable";
import { useState } from "react";

interface ReservesTabProps {
    reloadStats?(): void;
}

export function ReservesTab({ reloadStats }: ReservesTabProps) {
    const [filters, setFilters] = useState<VehicleTaskSearchModel>({} as VehicleTaskSearchModel);

    return (
        <>
            <AwaitingReservesFilter className="mt-2 mb-3" filters={filters} setFilters={setFilters} />
            <AwaitingReservesTable filters={filters} setFilters={setFilters} reloadStats={reloadStats} />
        </>
    );
}
