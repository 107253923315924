import DropdownModel from "@g3r-developers/vamp-types/dist/Dropdown/DropdownModel";
import Api from "./Api";

const BASE_URL = "dropdown";

const activeTransportStatuses = async (query?: string): Promise<DropdownModel[]> => {
    return Api.get<DropdownModel[]>(`${BASE_URL}/transport-statuses/active`, { query: query });
};

const addressesForCompanyByType = (type: "collection" | "delivery", query: string): Promise<DropdownModel[]> => {
    return Api.get<DropdownModel[]>(`${BASE_URL}/company/address/${type}`, {
        query: query,
    });
};

const attribvals = (attribName: string, query: string): Promise<DropdownModel[]> => {
    return Api.get<DropdownModel[]>(`${BASE_URL}/attribvals/${attribName}`, {
        query: query,
    });
};

const auctionLocations = (query: string): Promise<DropdownModel[]> => {
    return Api.get<DropdownModel[]>(`${BASE_URL}/location/company-auction`, { query: query });
};

const auctionSales = (query: string): Promise<DropdownModel[]> => {
    return Api.get<DropdownModel[]>(`${BASE_URL}/auction-sales`, { query: query });
};

const auctionSupplierCompaniesCurrentCompany = async (query: string): Promise<DropdownModel[]> => {
    return Api.get<DropdownModel[]>(`${BASE_URL}/vendor/supplier-companies/auction`, { query: query });
};

const collectionAddressesCurrentCompany = (query?: string): Promise<DropdownModel[]> => {
    return Api.get<DropdownModel[]>(`${BASE_URL}/address/collection`, { query: query });
};

const companyAuctionLocations = (query: string): Promise<DropdownModel[]> => {
    return Api.get<DropdownModel[]>(`${BASE_URL}/location/company-auction`, { query: query });
};

const compounds = async (query: string): Promise<DropdownModel[]> => {
    return Api.get<DropdownModel[]>(`${BASE_URL}/compound`, { query: query });
};

const deliveryLocations = async (query: string): Promise<DropdownModel[]> => {
    return Api.get<DropdownModel[]>(`${BASE_URL}/Location/Delivery`, { query: query });
};

const deliveryAddressesCurrentCompany = (query?: string): Promise<DropdownModel[]> => {
    return Api.get<DropdownModel[]>(`${BASE_URL}/address/delivery`, { query: query });
};

const fees = (query: string): Promise<DropdownModel[]> => {
    return Api.get<DropdownModel[]>(`${BASE_URL}/fees`, { query: query });
};

const feesForCompany = (query?: string): Promise<DropdownModel[]> => {
    return Api.get<DropdownModel[]>(`${BASE_URL}/company/fees`, { query: query });
};

const instructionPhases = async (query?: string): Promise<DropdownModel[]> => {
    return Api.get<DropdownModel[]>(`${BASE_URL}/instruction-phases`, { query: query });
};

const supplierCompaniesCurrentCompany = (query: string): Promise<DropdownModel[]> => {
    return Api.get<DropdownModel[]>(`${BASE_URL}/vendor/supplier-companies`, { query: query });
};

const suppliers = (query: string): Promise<DropdownModel[]> => {
    return Api.get<DropdownModel[]>(`${BASE_URL}/supplier`, {
        query: query,
    });
};

const supplierVendors = (query: string): Promise<DropdownModel[]> => {
    return Api.get(`${BASE_URL}/supplier/vendors`, { query: query });
};

const supplierVendorContacts = (vendorId: number, query: string): Promise<DropdownModel[]> => {
    return Api.get(`${BASE_URL}/supplier/vendor/${vendorId}/contacts`, { query: query });
};

const auctionSupplier = (query: string): Promise<DropdownModel[]> => {
    return Api.get<DropdownModel[]>(`dropdown/Auction/Companies`, {
        query: query,
    });
};

const vendorContactGroups = async (query: string): Promise<DropdownModel> => {
    return Api.get<DropdownModel>(`${BASE_URL}/vendor/contact-groups`, { query: query });
};

const supplierCompaniesForPreRecoverySearch = async (
    preRecoverySearchId: number,
    query: string
): Promise<DropdownModel[]> => {
    return Api.get<DropdownModel[]>(`${BASE_URL}/pre-recovery-search/${preRecoverySearchId}/company/supplier`, {
        query: query,
    });
};

const supplierVehicleInstructionContacts = async (
    vehicleInstructionId: number,
    query: string
): Promise<DropdownModel[]> => {
    return Api.get<DropdownModel[]>(`${BASE_URL}/supplier/vehicle-instruction/${vehicleInstructionId}/contact`, {
        query: query,
    });
};

const vendorVehicleInstructionContacts = async (
    vehicleInstructionId: number,
    query: string
): Promise<DropdownModel[]> => {
    return Api.get<DropdownModel[]>(`${BASE_URL}/vendor/vehicle-instruction/${vehicleInstructionId}/contact`, {
        query: query,
    });
};

const vendorCompanyContacts = async (query: string): Promise<DropdownModel[]> => {
    return Api.get<DropdownModel[]>(`${BASE_URL}/contacts/vendor`, { query: query });
};

const suppliersByType = async (supplierType: string, query: string): Promise<DropdownModel[]> => {
    return Api.get<DropdownModel[]>(`${BASE_URL}/supplier/${supplierType}`, { query: query });
};

const suppliersByTypeCompanyIds = async (supplierType: string, query: string): Promise<DropdownModel[]> => {
    return Api.get<DropdownModel[]>(`${BASE_URL}/supplier/${supplierType}/companyidreturnvalue`, { query: query });
};
const SupplierCompanyRemarketingLocationsWithDistance = async (
    supplierCompanyId: number,
    collectionAddressId: number,
    query: string
): Promise<DropdownModel[]> => {
    return Api.get<DropdownModel[]>(
        `${BASE_URL}/supplier/${supplierCompanyId}/${collectionAddressId}/locations/remarketing`,
        {
            query: query,
        }
    );
};
const supplierVehicleInstructionJobs = async (
    vehicleInstructionId: number,
    query: string
): Promise<DropdownModel[]> => {
    return Api.get<DropdownModel[]>(`${BASE_URL}/supplier/vehicle-instruction/${vehicleInstructionId}/jobs`, {
        query: query,
    });
};

const vendorHostileSupplierCompanies = async (query: string): Promise<DropdownModel[]> => {
    return Api.get<DropdownModel[]>(`${BASE_URL}/vendor/hostile-supplier-companies`, { query: query });
};

const DropdownService = {
    activeTransportStatuses,
    addressesForCompanyByType,
    attribvals,
    auctionLocations,
    auctionSales,
    auctionSupplier,
    auctionSupplierCompaniesCurrentCompany,
    collectionAddressesCurrentCompany,
    companyAuctionLocations,
    compounds,
    deliveryAddressesCurrentCompany,
    deliveryLocations,
    fees,
    feesForCompany,
    instructionPhases,
    supplierCompaniesCurrentCompany,
    supplierCompaniesForPreRecoverySearch,
    SupplierCompanyRemarketingLocationsWithDistance,
    suppliers,
    suppliersByType,
    suppliersByTypeCompanyIds,
    supplierVehicleInstructionContacts,
    supplierVehicleInstructionJobs,
    supplierVendorContacts,
    supplierVendors,
    vendorCompanyContacts,
    vendorContactGroups,
    vendorHostileSupplierCompanies,
    vendorVehicleInstructionContacts,
};

export default DropdownService;
