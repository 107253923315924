import AcknowledgeVehicleFeeTable from "Components/Supplier/VehicleFee/AcknowledgeVehicleFeeTable";
import { Row, Col } from "reactstrap";

export default function VehicleFeeResponsePage() {
    return (
        <Row className={"flex-column"}>
            <Col>
                <h1 className={"text-bold"}>Fee Responses</h1>
            </Col>

            <Col>
                <AcknowledgeVehicleFeeTable />
            </Col>
        </Row>
    );
}
