import { G3Dropdown, G3Modal } from "@g3r-developers/g3-common";
import { AuctionLotCreateModel } from "@g3r-developers/vamp-types/dist/Auction/AuctionLot/AuctionLotCreateModel";
import { VehicleDataTableReturnModel } from "@g3r-developers/vamp-types/dist/vehicle/VehicleDataTableReturnModel";
import useERecoveryToasts from "Hooks/UseERecoveryToasts";
import { useCallback, useMemo, useState } from "react";
import { Row, Col, Table } from "reactstrap";
import auctionSaleService from "Services/Auction/AuctionSaleService";
import DropdownService from "Services/DropdownService";
import { isEmpty } from "Services/Utils";

interface LotVehiclesModalProps {
    vehicles: VehicleDataTableReturnModel[];
    toggle(): void;
    reload(): void;
}

interface LotVehiclesModalErrors {
    sale?: string;
}

interface LocalFails {
    vehicle_id: number;
    message: string;
}

export default function LotVehiclesModal({ vehicles, toggle, reload }: LotVehiclesModalProps) {
    const [selectedSale, setSelectedSale] = useState<number>(undefined);
    const [errors, setErrors] = useState<LotVehiclesModalErrors>(undefined);
    const [localFails, setLocalFails] = useState<LocalFails[]>(undefined);

    const { errorToast, successToast } = useERecoveryToasts();

    const checkErrors = useCallback(() => {
        const e = {} as LotVehiclesModalErrors;

        if (!selectedSale) {
            e.sale = "Select a sale to lot the vehicles too";
        }

        setErrors(e);
        return !isEmpty(e);
    }, [selectedSale]);

    const handleLot = useCallback(async () => {
        const e = checkErrors();
        if (e) {
            return;
        }

        const fails: LocalFails[] = [];

        if (!isEmpty(vehicles)) {
            const promises = vehicles.map(_ => {
                return auctionSaleService
                    .lot({
                        auction_sale_id: selectedSale,
                        vehicle_id: _.vehicle_id,
                    } as AuctionLotCreateModel)
                    .then(() => {})
                    .catch(e => fails.push({ message: e, vehicle_id: _.vehicle_id } as LocalFails));
            });

            await Promise.all(promises);
        }

        if (!isEmpty(localFails)) {
            setLocalFails(fails);
            return;
        }

        reload();
        toggle();
        successToast("Vehicles have been lotted");
    }, [checkErrors, selectedSale, vehicles, successToast, reload, toggle, localFails]);

    const tableDetails = useMemo(() => {
        return (
            <>
                {vehicles &&
                    vehicles.map((row, indx: number) => (
                        <tr key={indx}>
                            <td>{row.vrm}</td>
                            <td>
                                {row.make_name} {row.model_name}
                            </td>
                            <td>{row.mileage_string}</td>
                        </tr>
                    ))}
            </>
        );
    }, [vehicles]);

    const handleSaleSelected = useCallback((val: number) => {
        setSelectedSale(val);
    }, []);

    const vehiclesText = useMemo(() => {
        return vehicles.length > 1 ? "Lot Vehicles" : "Lot Vehicle";
    }, [vehicles.length]);

    return (
        <G3Modal title={vehiclesText} toggle={toggle} saveText={vehiclesText} save={handleLot}>
            <G3Dropdown
                label="Sale"
                loadOptions={DropdownService.auctionSales}
                onChange={handleSaleSelected}
                error={errors?.sale}
            />

            {vehicles && (
                <Row className="mt-3">
                    <h3>Vehicles</h3>
                    {vehicles.map(_ => {
                        return (
                            <Col xs={12}>
                                <Table className={"g3-table table table-striped"}>
                                    <thead>
                                        <tr>
                                            <th>VRM</th>
                                            <th>Description</th>
                                            <th>Mileage</th>
                                        </tr>
                                    </thead>
                                    <tbody>{tableDetails}</tbody>
                                </Table>
                            </Col>
                        );
                    })}
                </Row>
            )}
            {localFails?.length > 0 && (
                <>
                    <strong>Errors:</strong>
                    <table>
                        {localFails.map(err => (
                            <tr key={err.vehicle_id}>
                                <td>
                                    <span className="vrm vrm20">
                                        {vehicles.find(vehicle => vehicle.vehicle_id === err.vehicle_id).vrm}
                                    </span>
                                    {err.message}
                                </td>
                            </tr>
                        ))}
                    </table>
                </>
            )}
        </G3Modal>
    );
}
