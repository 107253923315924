import { G3DataTable, IconButton } from "@g3r-developers/g3-common";
import { PagingModel } from "@g3r-developers/vamp-types/dist/paging-model";
import { UnacknowledgedVehicleFeeReturnModel } from "@g3r-developers/vamp-types/dist/vehicle/Fee/UnacknowledgedVehicleFeeReturnModel";
import { useCallback, useMemo, useState } from "react";
import supplierVendorFeeService from "Services/Supplier/VehicleFeeService";
import useERecoveryToasts from "Hooks/UseERecoveryToasts";

export default function AcknowledgeVehicleFeeTable() {
    const [filters, setFilters] = useState<PagingModel>();
    const getData = useCallback(
        (model: PagingModel) => supplierVendorFeeService.getUnacknowledgedVehicleFees(model),
        []
    );
    const handleReload = useCallback(() => setFilters(p => ({ ...p })), []);
    const { errorToast, successToast } = useERecoveryToasts();

    const handleAcknowledge = useCallback(
        (fee_id: number) => {
            supplierVendorFeeService
                .acknowledgeFee(fee_id)
                .then(() => {
                    handleReload();
                    successToast("Fee response successfully acknowledged");
                })
                .catch(errorToast);
        },
        [handleReload, successToast, errorToast]
    );
    const columns = useMemo(
        () => [
            {
                name: "VRM",
                selector: "vrm",
            },
            {
                name: "Make/Model",
                cell: (row: UnacknowledgedVehicleFeeReturnModel) => (
                    <span>
                        {row.make} {row.model}
                    </span>
                ),
            },
            {
                selector: "agreement_num",
                name: "Agreement Number",
            },
            {
                selector: "fee_name",
                name: "Fee Name",
            },
            {
                selector: "cost_string",
                name: "Cost",
            },
            {
                selector: "requested_by",
                name: "Requested By",
            },
            {
                selector: "date_requested_string",
                name: "Date Requested",
            },
            {
                selector: "outcome",
                name: "Outcome",
            },
            {
                name: "Rejection Reason",
                cell: (row: UnacknowledgedVehicleFeeReturnModel) => (
                    <span>{row.rejection_reason ? row.rejection_reason : "N/A"}</span>
                ),
            },
            {
                name: "Outcome Notes",
                cell: (row: UnacknowledgedVehicleFeeReturnModel) => (
                    <span>{row.outcome_notes ? row.outcome_notes : "N/A"}</span>
                ),
            },
            {
                selector: "vehicle_fee_id",
                name: "",
                cell: (row: UnacknowledgedVehicleFeeReturnModel) => (
                    <div className="w-100 text-end">
                        <IconButton
                            text="Acknowledge"
                            variant="secondary"
                            faIconString="fa fa-check"
                            onClick={() => handleAcknowledge(row.vehicle_fee_id)}
                        />
                    </div>
                ),
                grow: 2,
            },
        ],
        [handleAcknowledge]
    );

    return <G3DataTable columns={columns} load={getData} filters={filters} />;
}
