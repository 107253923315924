import { G3DataTable, G3Date, G3Dropdown, G3Input } from "@g3r-developers/g3-common";
import { ERecoveryInspectionDataTableReturnModel } from "@g3r-developers/vamp-types/dist/ERecoveryVendorAuction/ERecoveryInspectionDataTableReturnModel";
import { VehicleTaskSearchModel } from "@g3r-developers/vamp-types/dist/vehicle/task/VehicleTaskSearchModel";
import { useCallback, useMemo, useState } from "react";
import { Col, Row } from "reactstrap";
import DropdownService from "Services/DropdownService";
import vendorAuctionService from "Services/Vendor/VendorAuctionService";

export function InspectionsTab() {
    const [filters, setFilters] = useState<VehicleTaskSearchModel>({} as VehicleTaskSearchModel);

    const columns = useMemo(
        () => [
            {
                selector: "vrm",
                name: "VRM",
            },
            {
                cell: row => row.vin ?? "-",
                name: "VIN",
            },
            {
                name: "Make/Model",
                cell: (row: ERecoveryInspectionDataTableReturnModel) => `${row.make} ${row.model}`,
            },
            {
                name: "Agreement No.",
                selector: "agreement_num",
            },
            {
                name: "Inspection Requested",
                selector: "inspection_requested_string",
                compact: true,
            },
            {
                name: "Inspection Due",
                cell: row => (row.inspection_due_string === "N/A" ? "Pending" : row.inspection_due_string),
                compact: true,
            },
            {
                name: "Auction Location",
                selector: "auction_location",
            },
            {
                name: "Next Sale Date",
                cell: row => (row.next_sale_date ? row.next_sale_date_string : "Awaiting Allocation"),
                selector: "next_sale_date_string",
                compact: true,
            },
        ],
        []
    );

    const loadInspections = useCallback((paging: VehicleTaskSearchModel) => {
        return vendorAuctionService.getInspections(paging);
    }, []);

    const handleKeywordChange = useCallback(
        (newValue: string) => {
            setFilters((f: VehicleTaskSearchModel) => {
                return { ...f, keyword: newValue };
            });
            console.log(filters);
        },
        [setFilters, filters]
    );

    const handleAuctionLocationsChange = useCallback(
        (newValue: number) => {
            setFilters((f: VehicleTaskSearchModel) => {
                return { ...f, auction_location_id: newValue };
            });
        },
        [setFilters]
    );

    const handleSaleDateChange = useCallback(
        (newValue: string) => {
            setFilters((f: VehicleTaskSearchModel) => {
                return { ...f, sale_date: newValue };
            });
        },
        [setFilters]
    );

    const handleVehicleTaskStatusChange = useCallback(
        (newValue: number[]) => {
            setFilters((f: VehicleTaskSearchModel) => {
                return { ...f, vehicle_task_status_ids: newValue };
            });
        },
        [setFilters]
    );

    return (
        <>
            <Row>
                <Col md="2">
                    <G3Input type="text" label="Keyword" onChange={handleKeywordChange} value={filters.keyword} />
                </Col>

                <Col md="2">
                    <G3Dropdown
                        label="Auction Location"
                        loadOptions={(val: string) => DropdownService.auctionLocations(val)}
                        onChange={handleAuctionLocationsChange}
                    />
                </Col>
                <Col md="2">
                    <G3Date label="Next Sale Date" onChange={handleSaleDateChange} selectedDate={filters.sale_date} />
                </Col>
                <Col md="2">
                    <G3Dropdown
                        label="Vehicle Task Status"
                        loadOptions={(val: string) => DropdownService.attribvals("task_status", val)}
                        onChange={handleVehicleTaskStatusChange}
                        multiple
                    />
                </Col>
            </Row>
            <br />
            <G3DataTable columns={columns} load={loadInspections} filters={filters} />
        </>
    );
}
