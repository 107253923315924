import { LoginResponse } from "@g3r-developers/vamp-types/dist/User/LoginResponse";
import { LoginData } from "Components/Account/LoginForm";
import { sessionService } from "redux-react-session";
import Api from "./Api";

const BASE_URL = "auth";

export const isLoggedIn = async (): Promise<boolean> => {
    return sessionService
        .loadSession()
        .then((session: LoginResponse) => !!session?.token)
        .catch(() => false);
};

export const getUser = async (): Promise<LoginResponse> => {
    return sessionService.loadSession().catch(() => undefined);
};

export const login = async (data: LoginData): Promise<LoginResponse> => {
    return Api.post(`${BASE_URL}`, data).then(async (res: LoginResponse) => {
        await sessionService.saveSession(res);
        await sessionService.saveUser(res);
        return res;
    });
};

export const logout = async (): Promise<void> => {
    return Api.post(`${BASE_URL}/logout`).then(() => {
        sessionService.invalidateSession();
    });
};

const authService = {
    getUser,
    isLoggedIn,
    login,
    logout,
};

export default authService;
