import useSession from "Hooks/UseSession";
import React, { useCallback, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import authService from "Services/AuthService";
import { Role } from "Types/Enums/Role";
import auctionLinks from "./AuctionLinks";
import "./SideNav.scss";
import SideNavLink, { SideNavLinkProps } from "./SideNavLink/SideNavLink";
import supplierLinks from "./SupplierLinks";
import vendorLinks from "./VendorLinks";

export interface SideNavLinkPropsExtended extends SideNavLinkProps {
    component?: React.ReactNode;
}

export default function SideNav() {
    const [sidebarOpen, toggleSidebar] = useState<boolean>(false);

    const { session } = useSession();
    const navigate = useNavigate();

    const handleLogOut = useCallback(() => {
        authService.logout().then(() => {
            navigate("/login");
        });
    }, [navigate]);

    const sideNavLinks = useMemo(() => {
        const hasRole = (role: Role) => session?.contact_types?.includes(role);

        let links: SideNavLinkPropsExtended[] = [];

        if (hasRole(Role.Supplier) && !session?.supplier_types.includes("Remarketing")) {
            links = links.concat(supplierLinks);
        }

        if (hasRole(Role.Vendor)) {
            links = links.concat(vendorLinks);
        }

        if (hasRole(Role.Supplier) && session?.supplier_types.includes("Remarketing")) {
            links = links.concat(auctionLinks);
        }

        return links;
    }, [session]);

    return (
        <aside className={sidebarOpen ? "left-sidebar text-center pt-4" : "left-sidebar text-center pt-4 open-sidebar"}>
            <div
                onClick={() => toggleSidebar(!sidebarOpen)}
                className={sidebarOpen ? "sidebar-chevron" : "sidebar-chevron sidebar-rotate"}
            >
                <i className="fas fa-chevron-right chevron"></i>
            </div>

            <div className="scroll-sidebar">
                <Link to="/">
                    <img
                        src={"https://media.g3r.co.uk/e-recovery/e-recovery-logo.png"}
                        alt="E-Recovery Logo"
                        className="w-100 p-3"
                    />
                </Link>

                <nav className="sidebar-nav mt-3">
                    <ul className={"sidebar-items"}>
                        {sideNavLinks.map(l => (
                            <li className={"sidebar-item"} key={l.to}>
                                {l.component || <SideNavLink {...l} />}
                            </li>
                        ))}
                    </ul>
                </nav>

                <div className="sidebar-dropdown">
                    <nav className="sidebar-nav mt-3">
                        <ul>
                            <li className="sidebar-item">
                                <UncontrolledDropdown className="sidebar-dropdown-link">
                                    <DropdownToggle tag="span" caret>
                                        <i className="fa fa-user"></i>
                                        <span className="ms-3">{session?.contact?.full_name}</span>
                                    </DropdownToggle>

                                    <DropdownMenu>
                                        <DropdownItem onClick={handleLogOut}>Logout</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </aside>
    );
}
