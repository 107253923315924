import { DataTableResultModel } from "@g3r-developers/vamp-types/dist/DataTableResultModel";
import { NotificationModel } from "@g3r-developers/vamp-types/dist/Notification/NotificationModel";
import { UnreadNotificationModel } from "@g3r-developers/vamp-types/dist/Notification/UnreadNotificationModel";
import { PagingModel } from "@g3r-developers/vamp-types/dist/paging-model";
import { StatusReturnModel } from "@g3r-developers/vamp-types/dist/StatusReturnModel";
import Api from "./Api";

const BASE_URL = "notification";

const acknowledge = async (notificationId: number): Promise<NotificationModel> => {
    return Api.patch<NotificationModel>(`${BASE_URL}/${notificationId}/acknowledge`, {});
};

const acknowledgeAll = async (): Promise<StatusReturnModel> => {
    return Api.patch<StatusReturnModel>(`${BASE_URL}/acknowledge`, {});
};

const getUnreadCount = async (): Promise<UnreadNotificationModel> => {
    return Api.get<UnreadNotificationModel>(`${BASE_URL}/unread`);
};

const search = async (paging: PagingModel): Promise<DataTableResultModel<NotificationModel>> => {
    return Api.get<DataTableResultModel<NotificationModel>>(`${BASE_URL}`, paging);
};

const notificationService = {
    acknowledge,
    acknowledgeAll,
    getUnreadCount,
    search,
};

export default notificationService;
