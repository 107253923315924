import { DataTableResultModel } from "@g3r-developers/vamp-types/dist/DataTableResultModel";
import { CreateEAppraiseModel } from "@g3r-developers/vamp-types/dist/EAppraise/CreateEAppraiseModel";
import { EAppraiseDataTableReturnModel } from "@g3r-developers/vamp-types/dist/EAppraise/EAppraiseDataTableReturnModel";
import { EAppraiseHistoryModel } from "@g3r-developers/vamp-types/dist/EAppraise/EAppraiseHistoryModel";
import Api from "Services/Api";

const BASE_URL = "vendor/instruction";

const history = (vehicleInstructionId: number): Promise<EAppraiseHistoryModel> => {
    return Api.get<EAppraiseHistoryModel>(`${BASE_URL}/${vehicleInstructionId}/e-appraise/history`);
};

const sendEAppraiseToCustomer = (vehicleId: number, data: CreateEAppraiseModel) => {
    return Api.post(`${BASE_URL}/${vehicleId}/e-appraise`, data);
};

const search = (data: any): Promise<DataTableResultModel<EAppraiseDataTableReturnModel>> => {
    return Api.get(`${BASE_URL}/e-appraise`, data);
};

const eAppraiseService = {
    history,
    sendEAppraiseToCustomer,
    search,
};

export default eAppraiseService;
