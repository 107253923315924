import AuctionLotVehiclesPage from "Pages/Auction/Sales/AuctionLotVehiclesPage";
import { lazy } from "react";
import { Route, Routes } from "react-router-dom";

export default function AuctionRoutes() {
    const AuctionHomePage = lazy(() => import("Pages/Auction/AuctionHomePage"));
    const AuctionVehicleViewPage = lazy(() => import("Pages/Auction/Vehicle/AuctionVehicleViewPage"));
    const AuctionSalesPage = lazy(() => import("Pages/Auction/Sales/AuctionSalesPage"));

    return (
        <Routes>
            <Route element={<AuctionHomePage />} index />
            <Route element={<AuctionVehicleViewPage />} path="/vehicle/:vehicleId" />
            <Route element={<AuctionSalesPage />} path="/sales" />
            <Route element={<AuctionLotVehiclesPage />} path="/sale/lot" />
        </Routes>
    );
}
