import { CapModel } from "@g3r-developers/vamp-types/dist/vehicle/CapModel";

export function CapTableCell({ caps }: { caps: CapModel }) {
    return (
        <>
            {!caps && <span>Awaiting</span>}
            {caps && (
                <table className="w-100">
                    <tbody className="w-100">
                        <tr className="w-100">
                            <td className="w-50">Clean</td>
                            <td className="w-50">&pound;{caps?.value_trade_clean}</td>
                        </tr>
                        <tr>
                            <td className="w-50">Average</td>
                            <td className="w-50">&pound;{caps?.value_trade_avg}</td>
                        </tr>
                        <tr>
                            <td className="w-50">Below</td>
                            <td className="w-50">&pound;{caps?.value_trade_low}</td>
                        </tr>
                    </tbody>
                </table>
            )}
        </>
    );
}
