import { IconButton, showconfirmmodal } from "@g3r-developers/g3-common";
import { VendorVehicleRepairReturnModel } from "@g3r-developers/vamp-types/dist/Vendor/Auction/VendorVehicleRepairReturnModel";
import useERecoveryToasts from "Hooks/UseERecoveryToasts";
import { useCallback } from "react";
import vendorRefurbService from "Services/Vendor/VendorRefurbService";

interface RepairsActionCellProps {
    reload(): void;
    reloadStats(): void;
    row: VendorVehicleRepairReturnModel;
}

export default function RepairsActionCell({ reload, reloadStats, row }: RepairsActionCellProps) {
    const { successToast, errorToast } = useERecoveryToasts();

    const handleApprove = useCallback(() => {
        showconfirmmodal("Please confirm", "Are you sure you want to approve this Refurb?").then((resp: Boolean) => {
            if (!resp) {
                return;
            }

            vendorRefurbService
                .approve(row.vehicle_refurb_id)
                .then(() => {
                    successToast("The Refurb was successfully approved");
                    reload();
                    reloadStats();
                })
                .catch(errorToast);
        });
    }, [errorToast, reload, reloadStats, row, successToast]);

    const handleReject = useCallback(() => {
        showconfirmmodal("Please confirm", "Are you sure you want to reject this Refurb?").then((resp: Boolean) => {
            if (!resp) {
                return;
            }

            vendorRefurbService
                .reject(row.vehicle_refurb_id)
                .then(() => {
                    successToast("The Refurb was successfully rejected");
                    reload();
                    reloadStats();
                })
                .catch(errorToast);
        });
    }, [errorToast, reload, reloadStats, row, successToast]);

    return (
        <>
            <IconButton
                className="me-1"
                faIconString="fas fa-check"
                onClick={handleApprove}
                tooltip="Approve"
                variant="primary"
            />
            <IconButton faIconString="fas fa-times" onClick={handleReject} tooltip="Reject" variant="secondary" />
        </>
    );
}
