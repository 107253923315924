import Api from "../Api";

const BASE_URL = "vendor/refurb";

const approve = (vehicleRefurbId: number): Promise<void> => {
    return Api.post(`${BASE_URL}/${vehicleRefurbId}/approve`);
};

const reject = (vehicleRefurbId: number): Promise<void> => {
    return Api.post(`${BASE_URL}/${vehicleRefurbId}/reject`);
};

const vendorRefurbService = {
    approve,
    reject,
};

export default vendorRefurbService;
